import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import img_imgBanner1 from "../../assets/img/ci/banner/img-banner-1.png";

const BannerProfile = ({ banners }) => {
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    dots: true,
    infinite: true,
  };

  return (
    <>
      <Slider {...settings} className="slider">
        {banners.map((bannerItem, bannerIndex) => (
          <div key={bannerIndex + 1} className="bBannerImg">
            {bannerItem.embedLink !== null || bannerItem.embedLink !== "" ? (
              <a href={bannerItem.embedLink}>
                <img src={bannerItem.imageDesktop} />
              </a>
            ) : (
              <img src={bannerItem.imageDesktop} />
            )}
          </div>
        ))}
      </Slider>
    </>
  );
};

export default BannerProfile;
