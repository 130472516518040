import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import PageHeader from "../../components/header/PageHeader";

import useTranslations from "../../i18n/useTranslations";

import img_icoSuccess from "../../assets/img/ci/ico-success.svg";

const VerifySuccessPage = () => {
  const { t } = useTranslations();

  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateRegister = useSelector((state) => state.register); // ไว้ดึง state
  const stateProfile = useSelector((state) => state.profile);

  /* Handle goto page */
  const navigate = useNavigate();

  const handleGotoPage = (e) => {
    e.preventDefault();
    // console.log("click GotoPage", e.currentTarget.dataset.page);

    const page = e.currentTarget.dataset.page;
    navigate("/" + page);
  };
  /* End Handle goto page */

  /* First event render */
  useEffect(() => {
    // console.log("useEffect !", stateRegister, stateProfile);

    const { dataUser } = stateProfile;
    if (Object.keys(dataUser).length === 0) {
      navigate("/");
    }
  }, []);
  /* End First event render */

  return (
    <>
      <Helmet>
        <title>JIB</title>
        <meta name="title" content="JIB" />
        <meta name="description" content="JIB" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="JIB" />
        <meta property="og:description" content="JIB" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="JIB" />
      </Helmet>

      <div id="pageRegisterSuccess">
        <main>
          <PageHeader
            title={t.verifySuccessPage.headerTitle}
            btnBack="false"
            btnBackImgClassName=""
          />

          <div className="wrapContent">
            <section id="pRegisterSuccess">
              <div className="bDetail">
                <img src={img_icoSuccess} />
                <h2
                  dangerouslySetInnerHTML={{
                    __html: t.verifySuccessPage.bodyTitle,
                  }}
                ></h2>
                <button
                  className="btn primary openModalRegisterSuccess width-100"
                  onClick={handleGotoPage}
                  data-page="profile"
                >
                  {t.verifySuccessPage.btnSubmit}
                </button>
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default VerifySuccessPage;
