import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import useTranslations from "../../i18n/useTranslations";

import img_icoArrowLeftBack from "../../assets/img/ci/ico-arrow-left-back.svg";
import img_icoLogoHead from "../../assets/img/ci/ico-logo-head.svg";

const PageHeader = (props) => {
  const { lang, setLang } = useTranslations();

  /* Handle goto page */
  const navigate = useNavigate();

  const handleGotoPage = (e) => {
    e.preventDefault();
    // console.log("click GotoPage", e.currentTarget.dataset.page);

    const page = e.currentTarget.dataset.page;
    navigate("/" + page);
  };
  /* End Handle goto page */

  /* Handle select language */
  const handleSelectLanguage = (e) => {
    setLang(e.target.value);
  };

  // useEffect(() => {
  //   console.log(lang);
  // }, [lang]);
  /* End Handle select language */

  return (
    <>
      <header>
        <div className="bHeader">
          <a
            href="#"
            style={props.btnHref !== "" ? {} : { visibility: "hidden" }}
            onClick={handleGotoPage}
            data-page={props.btnHref}
          >
            <img
              className={`imgBlack ${props.btnBackImgClassName}`}
              src={img_icoArrowLeftBack}
            />
          </a>
          <div className="tHead">
            <img src={img_icoLogoHead} />
            <h1>{props.title}</h1>
          </div>
          <select
            name="lang"
            id="languge"
            data-lang={lang}
            value={lang}
            onChange={handleSelectLanguage}
          >
            {lang === "th" ? (
              <>
                <option value="th">ไทย</option>
                <option value="en">Eng</option>
              </>
            ) : (
              <>
                <option value="en">Eng</option>
                <option value="th">ไทย</option>
              </>
            )}
          </select>
        </div>
      </header>
    </>
  );
};

export default PageHeader;
