import React, { useEffect, useState } from "react";

import jBox from "jbox";
import $ from "jquery";

import useTranslations from "../../../i18n/useTranslations";

import img_icoDelete from "../../../assets/img/ci/popup/ico-delete.svg";

const ModalConfirmDelete = ({ open = false, onClose = (confirm) => {} }) => {
  const { t } = useTranslations();

  const [modal, setModal] = useState(null);

  const openModal = () => {
    modal.open();
  };

  const closeModal = (confirm) => {
    modal.close();
    onClose(confirm);
  };

  const listenerCloseModal = () => {
    const btnClose = document.querySelectorAll(
      "#ModalDeleteConfirm .btnCloseModal"
    );

    btnClose.forEach((item, index) => {
      item.addEventListener("click", () => {
        if (item.className.includes("openModalDeleteSuccess")) {
          closeModal(true);
        } else {
          closeModal(false);
        }
      });
    });
  };

  /* First event render */
  useEffect(() => {
    const initModal = new jBox("Modal", {
      closeButton: false,
      closeOnClick: false,
      minWidth: 342,
      maxWidth: 342,
      content: $("#ModalDeleteConfirm"),
    });
    setModal(initModal);

    return () => {
      initModal.destroy();
    };
  }, []);
  /* End First event render */

  useEffect(() => {
    if (modal && open) {
      openModal();
      listenerCloseModal();
    }
  }, [modal, open]);

  return (
    <>
      <div
        className="bModal bCard"
        id="ModalDeleteConfirm"
        style={{ display: "none" }}
      >
        <div className="bInner">
          <div className="closeBtn btnCloseModal"></div>
          <div className="tContent">
            <img src={img_icoDelete} />
            <p className="tHead error">
              {t.addressListPage.modal.confirmDelete.bodyTitle}
            </p>
            <p className="error">
              {t.addressListPage.modal.confirmDelete.bodyDetail}
            </p>
            <div className="bBtn">
              <a
                className="btn error btnCloseModal openModalDeleteSuccess"
                href="#"
              >
                {t.addressListPage.modal.confirmDelete.btnConfirm}
              </a>
              <a className="btn outlineError btnCloseModal" href="#">
                {t.addressListPage.modal.confirmDelete.btnCancel}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalConfirmDelete;
