import { createSlice } from "@reduxjs/toolkit";

const defaultData = {
  id: 0,
  data: {},
};

export const AddressSlice = createSlice({
  name: "Address",
  initialState: defaultData,
  reducers: {
    setAddress: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        // console.log(`${key}: ${value}`);
        state[key] = value;
      }
    },
    resetAddress: () => defaultData,
  },
});

// Action creators are generated for each case reducer function
export const { setAddress, resetAddress } = AddressSlice.actions;

export default AddressSlice.reducer;
