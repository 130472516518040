import React from "react";
import { Helmet } from "react-helmet";

import img_logo from "../../assets/img/ci/ico-logo.svg";

const PageSplash = () => {
  return (
    <>
      <Helmet>
        <title>JIB</title>
        <meta name="title" content="JIB" />
        <meta name="description" content="JIB" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="JIB" />
        <meta property="og:description" content="JIB" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="JIB" />
      </Helmet>

      <div id="pageRegister">
        <main>
          <div className="wrapContent">
            <section id="pRegister">
              <img src={img_logo} />
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default PageSplash;
