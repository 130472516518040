import React, { useEffect, useState } from "react";

import jBox from "jbox";
import $ from "jquery";

const ModalVerifyChangeMobileNo = ({
  open = false,
  onClose = (changeMobileNo) => {},
}) => {
  const [modal, setModal] = useState(null);

  const openModal = () => {
    modal.open();
  };

  const closeModal = (changeMobileNo) => {
    modal.close();
    onClose(changeMobileNo);
  };

  const listenerCloseModal = () => {
    const btnClose = document.querySelector(
      "#ModalChangeMobileNumber .btnCloseModal"
    );
    btnClose.addEventListener("click", () => {
      closeModal(false);
    });

    const btnConfirm = document.querySelector(
      "#ModalChangeMobileNumber .btnConfirm"
    );
    btnConfirm.addEventListener("click", () => {
      closeModal(true);
    });

    const btnCancel = document.querySelector(
      "#ModalChangeMobileNumber .btnCancel"
    );
    btnCancel.addEventListener("click", () => {
      closeModal(false);
    });
  };

  /* First event render */
  useEffect(() => {
    const initModal = new jBox("Modal", {
      closeButton: false,
      closeOnClick: false,
      minWidth: 325,
      maxWidth: 375,
      content: $("#ModalChangeMobileNumber"),
    });
    setModal(initModal);

    return () => {
      initModal.destroy();
    };
  }, []);
  /* End First event render */

  useEffect(() => {
    if (modal && open) {
      openModal();
      listenerCloseModal();
    }
  }, [modal, open]);

  return (
    <>
      <div
        className="bModal bCard"
        id="ModalChangeMobileNumber"
        style={{ display: "none" }}
      >
        <div className="bInner">
          <div className="closeBtn btnCloseModal"></div>
          <div className="tContent">
            <p className="tHead">คุณต้องการเปลี่ยนเบอร์โทรศัพท์</p>
            <p>
              การเปลี่ยนเบอร์โทรศัพท์จำเป็นต้องยืนยันเบอร์อีกครั้ง
              กดยืนยันเพื่อรับ OTP เพื่อเปลี่ยนเบอร์โทรศัพท์ของคุณ
            </p>
            <div className="bBtn">
              <button className="btn primary btnConfirm">ยืนยัน</button>
              <button className="btn outline btnCancel">ยกเลิก</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalVerifyChangeMobileNo;
