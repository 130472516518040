import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setRegister, resetRegister } from "../../features/RegisterSlice";
import { searchUser } from "../../services/Api/Module/User";

import Helper, {
  convertMobileFormat,
  breakKeyDownEnter,
} from "../../services/helper";
// import { PatternFormat } from "react-number-format";

import useTranslations from "../../i18n/useTranslations";

import img_icoLogo from "../../assets/img/ci/ico-logo.svg";

const RegisterPage = () => {
  const { t, lang, setLang } = useTranslations();

  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateRegister = useSelector((state) => state.register); // ไว้ดึง state

  const setDataRegister = (newData = {}) => {
    const tmpObj = { dataUser: newData };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const resetDataRegister = () => {
    dispatch(resetRegister()); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  /* Set state Register {currentPage} on goNext & goBack */
  const navigate = useNavigate();
  const [page, setPage] = useState("register");

  const PageRoute = {
    registerForm: "/register/form",
  };

  const setCurrentPage = (currentPage) => {
    const tmpObj = { currentPage: currentPage };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const setPrevPage = (prevPage) => {
    const tmpObj = { prevPage: prevPage };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const goBack = (newPage) => {
    setPrevPage(page);
    setCurrentPage(newPage);
    navigate(PageRoute[newPage]);
  };

  const goNext = (newPage) => {
    setPrevPage(page);
    setCurrentPage(newPage);
    navigate(PageRoute[newPage]);
  };
  /* End Set state Register {currentPage} on goNext & goBack */

  /* Form */
  const {
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    getFieldState,
  } = useForm({
    defaultValues: {
      email: "",
    },
  });

  const [formIsValid, setFormIsValid] = useState(false);
  const [formIsFocus, setFormIsFocus] = useState("");

  const handleCheckOnChange = () => {
    const _field = getValues();
    let _invalidState = true,
      _invalidFields = [];

    for (const key in _field) {
      const val = _field[key];
      // console.log(key, typeof val, val);

      let _invalid = getFieldState(key).invalid;
      if (val === "" || val === false || val === null) {
        _invalid = true;
      }

      if (key === "email") {
        let _value = val;

        if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(_value)) {
          _invalid = false;

          handleChkEmailExist(_value);
        }
      }

      _invalidFields.push(_invalid);
    }

    _invalidState = _invalidFields.includes(true);

    if (_invalidState === true) {
      setFormIsValid(false);
    } else {
      setFormIsValid(true);
    }

    // console.log("onChange", _invalidState);
  };

  useEffect(() => {
    // console.log("formIsValid >>", formIsValid);
    // console.log("formIsFocus >>", formIsFocus);
  }, [formIsValid, formIsFocus]);
  /* End Form */

  /* Handle click submit form */
  const onSubmit = (data) => {
    // console.log("onSubmit >>", data);

    setDataRegister(data);

    goNext("registerForm");
  };
  /* End Handle click submit form */

  /* Check email exist from server */
  const handleChkEmailExist = (email) => {
    // console.log(email);

    clearErrors("email");

    getSearchUser({
      email: email,
    }).then((_rsData) => {
      // console.log(_rsData);

      if (_rsData.status === true) {
        setFormIsValid(false);

        setError(
          "email",
          {
            type: "manual",
            message: t.form.email.validate.value.alreadyExist,
          },
          {
            shouldFocus: true,
          }
        );
      } else {
        clearErrors("email");
      }
    });
  };

  const getSearchUser = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await searchUser({ params: payload });
      // console.log("getSearchUser data >>", data);

      if (data.resultCode === 20200) {
        _return.status = true;
      }
    } catch (e) {
      console.log("ERR getSearchUser >> ", e);
    }

    return _return;
  };
  /* End Check email exist from server  */

  /* First event render */
  // useEffect(() => {
  //   // console.log("useEffect !", stateRegister);
  // }, []);
  /* End First event render */

  /* Handle select language */
  const handleSelectLanguage = (e) => {
    setLang(e.target.value);
  };

  // useEffect(() => {
  //   console.log(lang);
  // }, [lang]);
  /* End Handle select language */

  return (
    <>
      <Helmet>
        <title>JIB</title>
        <meta name="title" content="JIB" />
        <meta name="description" content="JIB" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="JIB" />
        <meta property="og:description" content="JIB" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="JIB" />
      </Helmet>

      <div id="pageRegisterNewMember">
        <main>
          <div className="wrapContent">
            <section id="pRegisterNewMember">
              <div className="bTitle">
                <div className="dummy"></div>
                <div className="bLogo">
                  <img src={img_icoLogo} alt="JIB" />{" "}
                  <h1
                    className="tHead"
                    dangerouslySetInnerHTML={{
                      __html: t.registerPage.headerTitle,
                    }}
                  ></h1>
                </div>
                <select
                  name="lang"
                  id="languge"
                  data-lang={lang}
                  value={lang}
                  onChange={handleSelectLanguage}
                >
                  {lang === "th" ? (
                    <>
                      <option value="th">ไทย</option>
                      <option value="en">Eng</option>
                    </>
                  ) : (
                    <>
                      <option value="en">Eng</option>
                      <option value="th">ไทย</option>
                    </>
                  )}
                </select>
              </div>
              <form
                onSubmit={handleSubmit(onSubmit)}
                onKeyDown={(e) => breakKeyDownEnter(e)}
              >
                <div className="bForm">
                  <div
                    className={`control-group validate ${
                      errors.email ? "error" : ""
                    }`}
                  >
                    <div className="tTitle">{t.form.email.label}</div>
                    <Controller
                      name="email"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: t.form.email.validate.required,
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: t.form.email.validate.pattern,
                        },
                      }}
                      render={({ field: { onChange, ...field } }) => (
                        <div className="controlPass">
                          <input
                            {...field}
                            type="text"
                            placeholder={`olivia@untitledui.com`}
                            onChange={({ target: { value } }) => {
                              onChange(value);
                              handleCheckOnChange();
                            }}
                            autoComplete="username"
                            onFocus={() => setFormIsFocus("email")}
                            maxLength="100"
                          />
                        </div>
                      )}
                    />
                    {errors.email && (
                      <label htmlFor="" className="tError">
                        {errors.email.message}
                      </label>
                    )}
                  </div>

                  <button
                    className="btn second btnDisRegis"
                    type="submit"
                    disabled={formIsValid ? false : true}
                  >
                    {t.registerPage.btnRegister}
                  </button>
                </div>
              </form>
              <div className="memberCheck">
                <a
                  className="tMemberCheck"
                  href="#"
                  onClick={() => {
                    navigate("/verify");
                  }}
                >
                  {t.registerPage.btnVerify}
                </a>
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default RegisterPage;
