import React, { useEffect, useState } from "react";

import jBox from "jbox";
import $ from "jquery";

import useTranslations from "../../../i18n/useTranslations";

import img_icoRight from "../../../assets/img/ci/popup/ico-right.svg";

const ModalEditProfileSuccess = ({ open = false, onClose = () => {} }) => {
  const { t } = useTranslations();

  const [modal, setModal] = useState(null);

  const openModal = () => {
    modal.open();
  };

  const closeModal = () => {
    modal.close();
    onClose();
  };

  const listenerCloseModal = () => {
    const btnClose = document.querySelector(
      "#ModalUpdateSuccess .btnCloseModal"
    );
    btnClose.addEventListener("click", () => {
      closeModal();
    });
  };

  /* First event render */
  useEffect(() => {
    const initModal = new jBox("Modal", {
      closeButton: false,
      closeOnClick: false,
      minWidth: 325,
      maxWidth: 375,
      content: $("#ModalUpdateSuccess"),
    });
    setModal(initModal);

    return () => {
      initModal.destroy();
    };
  }, []);
  /* End First event render */

  useEffect(() => {
    if (modal && open) {
      openModal();
      listenerCloseModal();
    }
  }, [modal, open]);

  return (
    <>
      <div
        className="bModal bCard"
        id="ModalUpdateSuccess"
        style={{ display: "none" }}
      >
        <div className="bInner">
          <div className="closeBtn btnCloseModal"></div>
          <div className="tContent">
            <img src={img_icoRight} />
            <p className="tHead">{t.editProfilePage.modal.success.bodyTitle}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalEditProfileSuccess;
