import { createSlice } from "@reduxjs/toolkit";

const defaultData = {
  id: 0,
  data: {},
  redeem: {},
};

export const CouponSlice = createSlice({
  name: "Coupon",
  initialState: defaultData,
  reducers: {
    setCoupon: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        // console.log(`${key}: ${value}`);
        state[key] = value;
      }
    },
    resetCoupon: () => defaultData,
  },
});

// Action creators are generated for each case reducer function
export const { setCoupon, resetCoupon } = CouponSlice.actions;

export default CouponSlice.reducer;
