import { get, post, put } from "../Config";

export function accessToken({ params = {}, lineUid }) {
  return get({ url: `/api/jib/line/v1/user/token/${lineUid}`, params });
}

export function searchUser({ params = {} }) {
  return get({ url: `/api/jib/line/v1/user/search`, params });
}

export function userRegister({ data = {} }) {
  return post({ url: `/api/jib/line/v1/user/register`, data, timeOut: 20000 });
}

export function updateUserVerify({ data = {} }) {
  return post({ url: `/api/jib/line/v1/user/update`, data, timeOut: 20000 });
}

export function userProfile({ params = {} }) {
  return get({ url: `/api/jib/line/v1/user/profile`, params });
}

export function updateUserProfile({ data = {}, id }) {
  return put({
    url: `/api/jib/line/v1/user/update/${id}`,
    data,
    timeOut: 20000,
  });
}
