import React, { useEffect, useState } from "react";

import jBox from "jbox";
import $ from "jquery";

import useTranslations from "../../../i18n/useTranslations";

const ModalEditProfileChangeMobileNo = ({
  open = false,
  onClose = (changeMobileNo) => {},
}) => {
  const { t } = useTranslations();

  const [modal, setModal] = useState(null);

  const openModal = () => {
    modal.open();
  };

  const closeModal = (changeMobileNo) => {
    modal.close();
    onClose(changeMobileNo);
  };

  const listenerCloseModal = () => {
    const btnClose = document.querySelectorAll(
      "#ModalConfirmMobileEdit .btnCloseModal"
    );

    btnClose.forEach((item, index) => {
      item.addEventListener("click", () => {
        if (item.className.includes("openModalVerifySuccess")) {
          closeModal(true);
        } else {
          closeModal(false);
        }
      });
    });
  };

  /* First event render */
  useEffect(() => {
    const initModal = new jBox("Modal", {
      closeButton: false,
      closeOnClick: false,
      minWidth: 325,
      maxWidth: 342,
      content: $("#ModalConfirmMobileEdit"),
    });
    setModal(initModal);

    return () => {
      initModal.destroy();
    };
  }, []);
  /* End First event render */

  useEffect(() => {
    if (modal && open) {
      openModal();
      listenerCloseModal();
    }
  }, [modal, open]);

  return (
    <>
      <div
        className="bModal bCard"
        id="ModalConfirmMobileEdit"
        style={{ display: "none" }}
      >
        <div className="bInner">
          <div className="closeBtn btnCloseModal"></div>
          <div className="tContent">
            <p className="tHead text-black">
              {t.editProfilePage.modal.changeMobileNo.bodyTitle}
            </p>
            <p
              className="text-black"
              dangerouslySetInnerHTML={{
                __html: t.editProfilePage.modal.changeMobileNo.bodyDetail,
              }}
            ></p>
            <div className="btnGroup">
              <a className="btn outline btnCloseModal">
                {t.editProfilePage.modal.changeMobileNo.btnCancel}
              </a>
              <a className="btn primary btnCloseModal openModalVerifySuccess">
                {t.editProfilePage.modal.changeMobileNo.btnSubmit}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalEditProfileChangeMobileNo;
