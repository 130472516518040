import React, { useEffect, useState } from "react";

import jBox from "jbox";
import $ from "jquery";

import useTranslations from "../../../i18n/useTranslations";

import img_RegisterSuccess from "../../../assets/img/ci/popup/img-register-success.png";
import img_RegisterSuccessEn from "../../../assets/img/ci/popup/img-register-success-eng.png";

const ModalRegisterSuccess = ({
  open = false,
  onClose = (route) => {},
  welcomePoint = 0,
}) => {
  const { t, lang } = useTranslations();

  const [modal, setModal] = useState(null);

  const openModal = () => {
    modal.open();
  };

  const closeModal = (route) => {
    modal.close();
    onClose(route);
  };

  const listenerCloseModal = () => {
    const btnProfile = document.querySelector(
      "#ModalRegisterSuccess .btnProfile"
    );
    btnProfile.addEventListener("click", () => {
      closeModal("/profile");
    });

    const btnMyProduct = document.querySelector(
      "#ModalRegisterSuccess .btnMyProduct"
    );
    btnMyProduct.addEventListener("click", () => {
      closeModal("/my-product");
    });
  };

  /* First event render */
  useEffect(() => {
    const initModal = new jBox("Modal", {
      closeButton: false,
      closeOnClick: false,
      minWidth: 325,
      maxWidth: 342,
      content: $("#ModalRegisterSuccess"),
    });
    setModal(initModal);

    return () => {
      initModal.destroy();
    };
  }, []);
  /* End First event render */

  useEffect(() => {
    if (modal && open) {
      openModal();
      listenerCloseModal();
    }
  }, [modal, open]);

  return (
    <>
      <div
        className="bModal bCard"
        id="ModalRegisterSuccess"
        style={{ display: "none" }}
      >
        <div className="bInner">
          <div className="bImg">
            <img
              src={lang === "th" ? img_RegisterSuccess : img_RegisterSuccessEn}
            />
          </div>
          <div className="tContent">
            <p className="tHead text-black">
              {welcomePoint !== 0
                ? `${t.registerSuccessPage.modal.success.bodyTitle.welcomePoint["0"]} ${welcomePoint} ${t.registerSuccessPage.modal.success.bodyTitle.welcomePoint["1"]}`
                : t.registerSuccessPage.modal.success.bodyTitle.other}
            </p>
            <p
              className="text-black"
              dangerouslySetInnerHTML={{
                __html: t.registerSuccessPage.modal.success.bodyDetail,
              }}
            ></p>
            <div className="bBtn">
              <button className="btn primary btnProfile">
                {t.registerSuccessPage.modal.success.btnProfile}
              </button>
              <button className="btn outline btnMyProduct">
                {t.registerSuccessPage.modal.success.btnShop}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalRegisterSuccess;
