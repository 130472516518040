import React, {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import ScrollToTop from "./components/utils/ScrollToTop";

import RegisterPage from "./pages/register";
import RegisterFormPage from "./pages/register/form";
import RegisterSurveyPage from "./pages/register/survey";
import RegisterOtpRequestPage from "./pages/register/otpRequest";
import RegisterSuccessPage from "./pages/register/success";

import VerifyPage from "./pages/verify";
import VerifyOtpRequestPage from "./pages/verify/otpRequest";
import VerifyFormPage from "./pages/verify/form";
import VerifySurveyPage from "./pages/verify/survey";
import VerifySuccessPage from "./pages/verify/success";

import ProfilePage from "./pages/account/profile";
import EditProfilePage from "./pages/account/profile/edit";
import EditProfileOtpRequestPage from "./pages/account/profile/otpRequest";
import AddressDeliveryListPage from "./pages/account/address/delivery";
import AddressDeliveryFormPage from "./pages/account/address/delivery/form";
import AddressTaxBillingListPage from "./pages/account/address/taxBilling";
import AddressTaxBillingFormPage from "./pages/account/address/taxBilling/form";

import ShopPage from "./pages/account/shop";

import PrivacyPolicyPage from "./pages/consent/privacyPolicy";
import NewsAndPromotionPage from "./pages/newsAndPromotion";

const appRouter = (props) => {
  // console.log(props.isHasProfile, props.profileData);

  return (
    <>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route
            path="/"
            element={
              props.isHasProfile ? (
                <Navigate to="/profile" />
              ) : (
                <Navigate to="/register" />
              )
            }
          />
          <Route
            path="/register"
            element={
              props.isHasProfile ? <Navigate to="/profile" /> : <RegisterPage />
            }
          />
          <Route
            path="/register/form"
            element={
              props.isHasProfile ? (
                <Navigate to="/profile" />
              ) : (
                <RegisterFormPage />
              )
            }
          />
          <Route
            path="/register/survey"
            element={
              props.isHasProfile ? (
                <Navigate to="/profile" />
              ) : (
                <RegisterSurveyPage />
              )
            }
          />
          <Route
            path="/register/otp/request"
            element={
              props.isHasProfile ? (
                <Navigate to="/profile" />
              ) : (
                <RegisterOtpRequestPage />
              )
            }
          />
          <Route
            path="/register/success"
            element={
              props.isHasProfile ? (
                <Navigate to="/profile" />
              ) : (
                <RegisterSuccessPage />
              )
            }
          />

          <Route
            path="/verify"
            element={
              props.isHasProfile ? <Navigate to="/profile" /> : <VerifyPage />
            }
          />
          <Route
            path="/verify/otp/request"
            element={
              props.isHasProfile ? (
                <Navigate to="/profile" />
              ) : (
                <VerifyOtpRequestPage />
              )
            }
          />
          <Route
            path="/verify/form"
            element={
              props.isHasProfile ? (
                <Navigate to="/profile" />
              ) : (
                <VerifyFormPage />
              )
            }
          />
          <Route
            path="/verify/survey"
            element={
              props.isHasProfile ? (
                <Navigate to="/profile" />
              ) : (
                <VerifySurveyPage />
              )
            }
          />
          <Route
            path="/verify/success"
            element={
              props.isHasProfile ? (
                <Navigate to="/profile" />
              ) : (
                <VerifySuccessPage />
              )
            }
          />

          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/profile/edit" element={<EditProfilePage />} />
          <Route
            path="/profile/edit/otp/request"
            element={<EditProfileOtpRequestPage />}
          />
          <Route
            path="/profile/address/delivery"
            element={<AddressDeliveryListPage />}
          />
          <Route
            path="/profile/address/delivery/add"
            element={<AddressDeliveryFormPage pageView="create" />}
          />
          <Route
            path="/profile/address/delivery/edit"
            element={<AddressDeliveryFormPage pageView="update" />}
          />
          <Route
            path="/profile/address/tax-billing"
            element={<AddressTaxBillingListPage />}
          />
          <Route
            path="/profile/address/tax-billing/add"
            element={<AddressTaxBillingFormPage pageView="create" />}
          />
          <Route
            path="/profile/address/tax-billing/edit"
            element={<AddressTaxBillingFormPage pageView="update" />}
          />

          <Route path="/shop" element={<ShopPage />} />

          {/* <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route
            path="/news-and-promotion"
            element={<NewsAndPromotionPage />}
          /> */}

          {/* Not found 404 */}
          <Route
            path="*"
            exact={true}
            // element={<Navigate replace to="/404" />}
            element={<Navigate replace to="/" />}
          />
        </Routes>
      </Router>
    </>
  );
};

export default appRouter;
