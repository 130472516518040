import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLiff } from "react-liff";

import VConsole from "vconsole";

import MainLayout from "./layouts/MainLayout";
import PageLoader from "./components/loader/PageLoader";
import PageSplash from "./components/loader/PageSplash";

import AppRouter from "./appRouter"; // Router

import { setProfile } from "./features/ProfileSlice";

import { accessToken } from "./services/Api/Module/User";

// const devMode = false;

function App() {
  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateProfile = useSelector((state) => state.profile);

  const [isLoadedProfile, setIsLoadedProfile] = useState(false);
  const [isHasProfile, setIsHasProfile] = useState(false);

  const { error, isLoggedIn, isReady, liff } = useLiff();

  // if (!isLoggedIn && devMode) {
  //   isLoggedIn = devMode; // mock true เอาไว้ dev
  // }

  const initialized = useRef(false);

  useEffect(() => {
    // console.log("isLoggedIn >>", isLoggedIn);

    if (error) {
      console.warn(error);
      return;
    }

    if (!isLoggedIn) return;

    if (!initialized.current) {
      initialized.current = true;

      (async () => {
        const profile = await liff.getProfile();

        if (profile) {
          const sub = liff.getDecodedIDToken().sub;
          // console.log(profile, sub);

          let tmpObj = {
            id: 0,
            lineUid: sub,
            lineImgUrl: profile.pictureUrl,
          };

          getAccessToken({
            params: {},
            lineUid: sub,
          }).then((_rsData) => {
            // console.log("_rsData >>", _rsData);

            if (typeof _rsData.result.token !== "undefined") {
              window.localStorage.setItem("token", _rsData.result.token);
            }

            if (_rsData.status === true) {
              // console.log(sub, _rsData.result);

              if (
                _rsData.result !== null &&
                typeof _rsData.result.id !== "undefined"
              ) {
                tmpObj = {
                  ...tmpObj,
                  id: _rsData.result.id,
                };

                setIsHasProfile(true);
              }

              dispatch(setProfile(tmpObj));

              setIsLoadedProfile(true);
            }
          });
        } else {
          console.warn(profile);
        }
      })();
    }
  }, [liff, isLoggedIn]);

  useEffect(() => {
    // console.log("isLoadedProfile >>", isLoadedProfile, isHasProfile);
    if (isLoadedProfile) {
      const vConsole = new VConsole();
    }
  }, [isLoadedProfile, isHasProfile]);

  const getAccessToken = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await accessToken({
        params: payload.params,
        lineUid: payload.lineUid,
      });
      // console.log("getAccessToken data >>", data);

      if (data.resultCode === 20200) {
        _return.status = true;
        _return.result = data.resultData;
      }
    } catch (e) {
      console.log("ERR getAccessToken >> ", e);
    }

    return _return;
  };

  return (
    <>
      {!isReady ? (
        <PageLoader />
      ) : !isLoadedProfile ? (
        <PageSplash />
      ) : (
        <MainLayout>
          <AppRouter isHasProfile={isHasProfile} profileData={stateProfile} />
        </MainLayout>
      )}
    </>
  );
}

export default App;
