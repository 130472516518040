import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setAddress, resetAddress } from "../../../../features/AddressSlice";
import {
  userAddress,
  updateAddress,
} from "../../../../services/Api/Module/UserAddress";

import Helper, {
  convertDateFormat,
  convertMobileFormat,
  breakKeyDownEnter,
} from "../../../../services/helper";

import PageHeader from "../../../../components/header/PageHeader";
import MenuMember from "../../../../components/menu/MenuMember";
import ModalConfirmDelete from "../../../../components/modal/address/ModalConfirmDelete";
import ModalDeleteSuccess from "../../../../components/modal/address/ModalDeleteSuccess";

import useTranslations from "../../../../i18n/useTranslations";

import img_icoEditBlack from "../../../../assets/img/ci/ico-edit-black.svg";
import img_icoDelete from "../../../../assets/img/ci/ico-delete.svg";

const AddressTaxBillingListPage = () => {
  const { t } = useTranslations();

  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateProfile = useSelector((state) => state.profile);
  const stateAddress = useSelector((state) => state.address);

  const setDataAddress = (id, data) => {
    const tmpObj = {
      id: id,
      data: data,
    };
    dispatch(setAddress(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const resetDataAddress = () => {
    dispatch(resetAddress()); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  /* Handle goto page */
  const navigate = useNavigate();

  const handleGotoPage = (e) => {
    e.preventDefault();
    // console.log("click GotoPage", e.currentTarget.dataset.page);

    const page = e.currentTarget.dataset.page;

    if (page.includes("tax-billing/edit")) {
      const id = parseInt(e.currentTarget.dataset.id);

      const _addressDefault = parseInt(
        document.querySelector('input[name="isDefault"]:checked').value
      );

      const _address = addressList.filter((item, index) => item.id === id);

      setDataAddress(_address[0].id, {
        ..._address[0],
        isDefault: _addressDefault === _address[0].id ? true : false,
      });
    }

    navigate("/" + page);
  };
  /* End Handle goto page */

  /* Handle click is default */
  const [isDefaultValue, setIsDefaultValue] = useState(null);

  const handleIsDefaultChange = (e) => {
    // console.log(e.target, e.target.value, e.target.checked);

    setIsDefaultValue(e.target.value);

    const addressId = e.target.value;

    const payload = {
      id: addressId,
      userId: stateProfile.dataUser.id,
      addressType: 2,
      isDefault: true,
    };
    // console.log(addressId, payload);

    postUpdUserAddress(payload).then((_rsData) => {
      // console.log("_rsData >>", _rsData);

      if (_rsData.status === true) {
      } else {
        console.warn(_rsData);
      }
    });
  };
  /* End Handle click is default */

  /* First event render */
  const [addressList, setAddressList] = useState([]);

  const getUserAddress = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await userAddress({ params: payload });
      // console.log("getUserAddress data >>", data);

      if (data.resultCode === 20200) {
        _return.status = true;
        _return.result = data.resultData;
      }
    } catch (e) {
      console.log("ERR getUserAddress >> ", e);
    }

    return _return;
  };

  const handleAddressList = () => {
    // console.log(addressList.length);

    if (addressList.length !== 0) {
      setAddressList([]);
    }

    getUserAddress({
      userId: stateProfile.id,
      addressType: 2,
    }).then((_rsData) => {
      // console.log("_rsData >>", _rsData);

      if (_rsData.status === true) {
        if (_rsData.result.length > 0) {
          const _length = _rsData.result.length;
          const _address = _rsData.result;
          // console.log(_address, _length);

          for (let i = 0; i < _length; i++) {
            setAddressList((prevItem) => [...prevItem, _address[i]]);
          }
        }
      }
    });
  };

  useEffect(() => {
    // console.log("useEffect !", stateProfile);

    if (Object.getOwnPropertyNames(stateProfile.dataUser).length === 0) {
      navigate("/");
    } else {
      if (Object.getOwnPropertyNames(stateAddress.data).length !== 0) {
        resetDataAddress();
      }

      handleAddressList();
    }
  }, []);

  useEffect(() => {
    // console.log("addressList >>", addressList, addressList.length);
  }, [addressList]);
  /* End First event render */

  /* Handle delete address */
  const [addressId, setAddressId] = useState(null);

  const postUpdUserAddress = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await updateAddress({ data: payload });
      // console.log("data.body >>", data);

      _return.result = data.resultData;

      if (data.resultCode === 20202) {
        _return.status = true;
      }
    } catch (e) {
      console.log("ERR postUpdUserAddress >> ", e);
    }

    return _return;
  };

  const handleDeleteAddress = (e) => {
    // console.log(e.currentTarget, e.currentTarget.dataset.id);

    const _id = e.currentTarget.dataset.id;

    setAddressId(_id);

    handleOpenModelConfirmDelete();
  };
  /* End Handle delete address */

  /* Modal confirm delete and delete success */
  const [isModalConfirmDeleteShow, setIsModalConfirmDeleteShow] =
    useState(false);
  const [isModalDeleteSuccessShow, setIsModalDeleteSuccessShow] =
    useState(false);

  const handleOpenModelConfirmDelete = () => {
    setIsModalConfirmDeleteShow(true);
  };

  const handleCloseModalConfirmDelete = (confirm) => {
    setIsModalConfirmDeleteShow(false);

    if (confirm === true) {
      const payload = {
        id: addressId,
        userId: stateProfile.dataUser.id,
        status: "deleted",
      };
      // console.log(addressId, payload);

      postUpdUserAddress(payload).then((_rsData) => {
        // console.log("_rsData >>", _rsData);

        if (_rsData.status === true) {
          handleOpenModelDeleteSuccess();
        } else {
          console.warn(_rsData);
        }
      });
    }
  };

  const handleOpenModelDeleteSuccess = () => {
    setIsModalDeleteSuccessShow(true);
  };

  const handleCloseModalDeleteSuccess = () => {
    setIsModalDeleteSuccessShow(false);

    handleAddressList();
  };
  /* End Modal confirm delete and delete success */

  return (
    <>
      <Helmet>
        <title>JIB</title>
        <meta name="title" content="JIB" />
        <meta name="description" content="JIB" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="JIB" />
        <meta property="og:description" content="JIB" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="JIB" />
      </Helmet>

      <div id="pageEditAddress">
        <main>
          <PageHeader
            title={t.addressListPage.headerTitle.tax}
            btnBackImgClassName="block"
            btnHref="profile"
          />

          <div className="wrapContent">
            <ModalConfirmDelete
              open={isModalConfirmDeleteShow}
              onClose={(confirm) => {
                handleCloseModalConfirmDelete(confirm);
              }}
            />
            <ModalDeleteSuccess
              open={isModalDeleteSuccessShow}
              onClose={handleCloseModalDeleteSuccess}
            />

            <section id="pEditAddress">
              <div className="bContent">
                <div className="bHead">{t.addressListPage.bodyTitle.tax}</div>
                {addressList.length > 0 &&
                  addressList.map((addressItem, addressIdx) => (
                    <div className="bAddress" key={addressIdx + 1}>
                      <div className="selectAddress">
                        <div className="tName">
                          <p className="tBold">{addressItem.name}</p>
                          <p>
                            {`(${convertMobileFormat(
                              addressItem.mobileNo,
                              "-"
                            )})`}
                          </p>
                        </div>
                        <div className="tTaxNUmber">
                          {t.addressListPage.card.taxNo} : {addressItem.taxNo}
                        </div>
                        <div className="tAddress">
                          <p>{t.addressListPage.card.address} :</p>
                          <p>{`${addressItem.addressDetail} ${addressItem.tombon.nameTh} ${addressItem.amphure.nameTh} ${addressItem.province.nameTh} ${addressItem.postcode}`}</p>
                        </div>
                        <div className="bHandle">
                          <div className="bToggle">
                            <div className="btnToggle">
                              <label className=" control--checkbox">
                                <input
                                  type="radio"
                                  name={`isDefault`}
                                  id={`isDefault${addressItem.id}`}
                                  value={addressItem.id}
                                  defaultChecked={
                                    addressItem.isDefault === true ||
                                    isDefaultValue === addressItem.id
                                  }
                                  onChange={handleIsDefaultChange}
                                />
                                <div className="control__toggle">
                                  <div className="circle"></div>
                                </div>
                              </label>
                            </div>
                            <p>{t.addressListPage.card.setDefaultAddress}</p>
                          </div>
                          <div className="bEdit">
                            <a
                              className="openModalConfirmMobileEdit"
                              href="#"
                              onClick={handleGotoPage}
                              data-page="profile/address/tax-billing/edit"
                              data-id={addressItem.id}
                            >
                              <img src={img_icoEditBlack} />
                              <p className="tEdit">
                                {t.addressListPage.card.editAddress}
                              </p>
                            </a>
                            <a
                              className="openModalDeleteConfirm"
                              href="#"
                              onClick={handleDeleteAddress}
                              data-id={addressItem.id}
                            >
                              <img src={img_icoDelete} />
                              <p className="tDelete">
                                {t.addressListPage.card.deleteAddress}
                              </p>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              {addressList.length < 5 && (
                <div className="bBtn">
                  <a
                    className="btn add outline width-100"
                    href="#"
                    onClick={handleGotoPage}
                    data-page="profile/address/tax-billing/add"
                  >
                    {t.addressListPage.card.addNewAddress}
                  </a>
                </div>
              )}
            </section>
            <MenuMember active="profile" />
          </div>
        </main>
      </div>
    </>
  );
};

export default AddressTaxBillingListPage;
