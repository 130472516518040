import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setProfile } from "../../../features/ProfileSlice";
import { OtpRequest, OtpVerify } from "../../../services/Api/Module/Otp";
import { updateUserProfile } from "../../../services/Api/Module/User";

import Helper, {
  convertMobileFormat,
  breakKeyDownEnter,
} from "../../../services/helper";

import PageHeader from "../../../components/header/PageHeader";
import ModalEditProfileOtpRequestSuccess from "../../../components/modal/editProfile/ModalEditProfileOtpRequestSuccess";

import useTranslations from "../../../i18n/useTranslations";

const EditProfileOtpRequestPage = () => {
  const { t } = useTranslations();

  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateProfile = useSelector((state) => state.profile);

  const setDataProfile = (newData) => {
    const tmpObj = { dataUser: newData };
    dispatch(setProfile(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const setDataUpdateProfile = (updData = {}) => {
    const tmpObj = { updData: updData };
    dispatch(setProfile(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  /* Handle goto page */
  const navigate = useNavigate();

  const handleGotoPage = (e) => {
    e.preventDefault();
    // console.log("click GotoPage", e.currentTarget.dataset.page);

    const page = e.currentTarget.dataset.page;
    navigate("/" + page);
  };
  /* End Handle goto page */

  /* Form */
  const {
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    getFieldState,
  } = useForm({
    defaultValues: {
      otp: "",
    },
  });

  const [formIsValid, setFormIsValid] = useState(false);
  const [otpIsValid, setOtpIsValid] = useState("");

  const handleCheckOnChange = () => {
    const _field = getValues();
    let _invalidState = true,
      _invalidFields = [];

    for (const key in _field) {
      const val = _field[key];
      // console.log(key, typeof val, val);

      let _invalid = getFieldState(key).invalid;
      if (val === "" || val === false || val === null) {
        _invalid = true;
      }

      if (key === "otp" && val.length === 6) {
        postOtpVerify({
          requestId: otpRequestId,
          code: val,
        }).then((_rsData) => {
          // console.log(_rsData);

          // const _headerPage = document.querySelector("#pRegisterMobileVerify .bHead");

          if (_rsData.status === true) {
            _invalid = false;

            setFormIsValid(true);
            setOtpIsValid("success");

            clearErrors("otp");
          } else {
            _invalid = true;

            setFormIsValid(false);
            setOtpIsValid("error");

            if (_rsData.result.status === 7) {
              setError(
                "otp",
                {
                  type: "manual",
                  message: t.form.otp.validate.value.incorrect,
                },
                {
                  shouldFocus: true,
                }
              );
            } else if (_rsData.result.status === 8) {
              setError(
                "otp",
                {
                  type: "manual",
                  message: t.form.otp.validate.value.expired,
                },
                {
                  shouldFocus: true,
                }
              );
            } else if (_rsData.result.status === 9) {
              setError(
                "otp",
                {
                  type: "manual",
                  message: t.form.otp.validate.value.limitRequest,
                },
                {
                  shouldFocus: true,
                }
              );
            } else {
              //
            }
          }
        });
      }

      _invalidFields.push(_invalid);
    }

    // _invalidState = _invalidFields.includes(true);

    // if (_invalidState === true) {
    //   setFormIsValid(false);
    // } else {
    //   setFormIsValid(true);
    // }

    // console.log("onChange", _invalidState);
  };

  useEffect(() => {
    // console.log("formIsValid >>", formIsValid);
    // console.log("otpIsValid >>", otpIsValid);
  }, [formIsValid, otpIsValid]);

  const postOtpVerify = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const _rs = await OtpVerify({ data: payload });
      // console.log("response >>", _rs);

      let data = null;
      if (
        typeof _rs.response !== "undefined" &&
        typeof _rs.response.data !== "undefined"
      ) {
        data = _rs.response.data;
      } else {
        data = _rs.data;
      }
      // console.log("data.body >>", data);

      _return.result = data.resultData;

      if (typeof data.resultCode !== "undefined" && data.resultCode === 20200) {
        _return.status = true;
      }
    } catch (e) {
      console.log("ERR postOtpVerify >> ", e);
    }

    return _return;
  };
  /* End Form */

  /* Handle click submit form */
  const postUpdUserProfile = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await updateUserProfile({
        data: payload.data,
        id: payload.id,
      });
      // console.log("data.body >>", data);

      _return.result = data.resultData;

      if (data.resultCode === 20202) {
        _return.status = true;
      }
    } catch (e) {
      console.log("ERR postUpdUserProfile >> ", e);
    }

    return _return;
  };

  const onSubmit = (data) => {
    // console.log("onSubmit >>", data, stateProfile);

    const payload = {
      data: { ...stateProfile.updData },
      id: stateProfile.id,
    };

    postUpdUserProfile(payload).then((_rsData) => {
      // console.log("_rsData >>", _rsData);

      if (_rsData.status === true) {
        setDataProfile(_rsData.result);

        setDataUpdateProfile();

        handleOpenModel();
      } else {
        console.warn(_rsData);
      }
    });
  };
  /* End Handle click submit form */

  /* Modal update profile success */
  const [isModalShow, setIsModalShow] = useState(false);

  const handleOpenModel = () => {
    setIsModalShow(true);
  };

  const handleCloseModal = () => {
    setIsModalShow(false);

    setTimeout(() => navigate("/profile"), 200);
  };
  /* End Modal update profile success */

  /* Handle Renew Otp Request */
  const handleRenewOtpRequest = (e) => {
    const { updData } = stateProfile;
    handleOtpRequest(updData.mobileNo);
  };
  /* End Handle Renew Otp Request */

  /* First event render */
  const postOtpRequest = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await OtpRequest({ data: payload });
      // console.log("data.body >>", data);

      _return.result = data.resultData;

      if (data.resultCode === 20200) {
        _return.status = true;
      }
    } catch (e) {
      console.log("ERR postOtpRequest >> ", e);
    }

    return _return;
  };

  const [otpRequestId, setOtpRequestId] = useState(null);
  const [otpRefCode, setOtpRefCode] = useState(null);

  const handleOtpRequest = (mobileNo) => {
    // console.log(mobileNo.replace(/^.{1}/g, "66"));

    postOtpRequest({
      mobileNo: mobileNo.replace(/^.{1}/g, "66"),
    }).then((_rsData) => {
      // console.log(_rsData);

      if (_rsData.status === true) {
        setOtpRequestId(_rsData.result.requestId);
        setOtpRefCode(_rsData.result.refCode);
      } else {
        console.warn(_rsData);
      }
    });
  };

  useEffect(() => {
    console.log("useEffect !", stateProfile);

    const { updData } = stateProfile;
    if (Object.keys(updData).length !== 0) {
      if (updData.mobileNo) {
        handleOtpRequest(updData.mobileNo);
      }
    } else {
      navigate("/");
    }
  }, []);
  /* End First event render */

  useEffect(() => {
    // console.log("otpRequestId >>", otpRequestId, "otpRefCode >>", otpRefCode);
  }, [otpRequestId, otpRefCode]);

  return (
    <>
      <Helmet>
        <title>JIB</title>
        <meta name="title" content="JIB" />
        <meta name="description" content="JIB" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="JIB" />
        <meta property="og:description" content="JIB" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="JIB" />
      </Helmet>

      <div id="pageRegisterMobileVerify">
        <main>
          <PageHeader
            title={t.editProfileOtpRequestPage.headerTitle}
            btnBackImgClassName=""
            btnHref=""
          />

          <div className="wrapContent">
            <ModalEditProfileOtpRequestSuccess
              open={isModalShow}
              onClose={handleCloseModal}
            />

            <section id="pRegisterMobileVerify">
              <div className="bDetail">
                <div className="tSubDetail">
                  <p className="tTitle">
                    {t.editProfileOtpRequestPage.bodyTitle}
                  </p>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t.editProfileOtpRequestPage.bodyDetail,
                    }}
                  ></p>
                </div>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  onKeyDown={(e) => breakKeyDownEnter(e)}
                >
                  <div className="bForm">
                    <div
                      className={`control-group validate ${otpIsValid} ${
                        errors.otp ? "error" : ""
                      }`}
                    >
                      <div className="tTitle">{t.form.otp.label}</div>
                      <Controller
                        name="otp"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: t.form.otp.validate.required,
                          maxLength: {
                            value: 6,
                            message: t.form.otp.validate.maxLength,
                          },
                          minLength: {
                            value: 6,
                            message: t.form.otp.validate.minLength,
                          },
                        }}
                        render={({ field: { onChange, ...field } }) => (
                          <div className="inputIcon">
                            <input
                              {...field}
                              type="text"
                              placeholder={t.form.otp.placeholder}
                              maxLength={6}
                              minLength={6}
                              onInput={Helper.FNFORM.handleNumberOnly}
                              onChange={({ target: { value } }) => {
                                onChange(value);
                                handleCheckOnChange();
                              }}
                            />
                          </div>
                        )}
                      />
                      {errors.otp && (
                        <label htmlFor="" className="tError">
                          {errors.otp.message}
                        </label>
                      )}
                    </div>
                    <p className="tVerify">
                      {t.editProfileOtpRequestPage.resendOtp["0"]}{" "}
                      <a href="#" onClick={handleRenewOtpRequest}>
                        {t.editProfileOtpRequestPage.resendOtp["1"]}
                      </a>
                    </p>
                    <button
                      className="btn primary"
                      type="submit"
                      disabled={formIsValid ? false : true}
                    >
                      {t.editProfileOtpRequestPage.btnSubmit}
                    </button>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default EditProfileOtpRequestPage;
