import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const pageLoaderStyle = {
  display: "flex",
  width: "100%",
  height: "100dvh",
  justifyContent: "center",
  alignItems: "center",
  color: "#ddd",
};

const PageLoader = () => {
  return (
    <div style={pageLoaderStyle}>
      <p><FontAwesomeIcon icon={faSpinner} spin /> Loading...</p>
    </div>
  );
}

export default PageLoader;