import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { banner } from "../../../services/Api/Module/Banner";

import PageHeader from "../../../components/header/PageHeader";
import MenuMember from "../../../components/menu/MenuMember";
import BannerShop from "../../../components/banner/bannerShop";

import img_imgShop from "../../../assets/img/ci/img-shop.png";
import img_icoSocial1 from "../../../assets/img/ci/social/ico-social1.png";
import img_icoSocial2 from "../../../assets/img/ci/social/ico-social2.png";
import img_icoSocial3 from "../../../assets/img/ci/social/ico-social3.png";

const ShopPage = () => {
  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateProfile = useSelector((state) => state.profile);

  /* Handle goto page */
  const navigate = useNavigate();

  const handleGotoPage = (e) => {
    e.preventDefault();
    // console.log("click GotoPage", e.currentTarget.dataset.page);

    const page = e.currentTarget.dataset.page;
    navigate("/" + page);
  };
  /* End Handle goto page */

  /* First event render */
  const [bannerList, setBannerList] = useState([]);

  const getBanner = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await banner({ params: payload });
      // console.log("getBanner data >>", data);

      if (data.resultCode === 20200) {
        _return.status = true;
        _return.result = data.resultData;
      }
    } catch (e) {
      console.log("ERR getBanner >> ", e);
    }

    return _return;
  };

  useEffect(() => {
    // console.log("useEffect !", stateProfile);

    if (stateProfile.id === 0) {
      navigate("/");
    } else {
      getBanner({
        platformId: 1,
        pageId: 2,
      }).then((_rsData) => {
        // console.log("_rsData >>", _rsData);

        if (_rsData.status === true) {
          if (_rsData.result !== null) {
            if (
              typeof _rsData.result.bannerPages[0] !== "undefined" &&
              typeof _rsData.result.bannerPages[0].bannerList !== "undefined"
            ) {
              const _bannerlist = _rsData.result.bannerPages[0].bannerList;

              for (let i = 0; i < _bannerlist.length; i++) {
                setBannerList((prevItem) => [...prevItem, _bannerlist[i]]);
              }
            }
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    // console.log("bannerPage >>", bannerList);
  }, [bannerList]);
  /* End First event render */

  return (
    <>
      <Helmet>
        <title>JIB</title>
        <meta name="title" content="JIB" />
        <meta name="description" content="JIB" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="JIB" />
        <meta property="og:description" content="JIB" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="JIB" />
      </Helmet>

      <div id="pageShop">
        <main>
          <PageHeader title="" btnBackImgClassName="" btnHref="" />

          <div className="wrapContent">
            <section id="pShop">
              <div
                className={`bShop ${
                  bannerList.length === 0 ? "hideBanner" : ""
                }`}
              >
                {bannerList.length > 0 &&
                  (bannerList.length > 1 ? (
                    <BannerShop banners={bannerList} />
                  ) : (
                    <div className="sliderShop">
                      {bannerList.map((bannerItem, bannerIndex) => (
                        <div key={bannerIndex + 1} className="bBannerImg">
                          {bannerItem.embedLink !== null ||
                          bannerItem.embedLink !== "" ? (
                            <a href={bannerItem.embedLink}>
                              <img src={bannerItem.imageDesktop} />
                            </a>
                          ) : (
                            <img src={bannerItem.imageDesktop} />
                          )}
                        </div>
                      ))}
                    </div>
                  ))}
                <div className="btnGroup">
                  <a
                    href="https://www.jib.co.th/web"
                    className="btnJib btn primary width-100"
                  >
                    Online Shopping
                  </a>
                  <div className="bSocial">
                    <a
                      href="https://shopee.co.th/jib_officialshop"
                      className="social1"
                    >
                      <img src={img_icoSocial1} alt="Shopee" />
                      <p className="tApp">Shopee</p>
                    </a>
                    <a
                      href="https://www.lazada.co.th/shop/jib-computer-group"
                      className="social2"
                    >
                      <img src={img_icoSocial2} alt="Lazada" />
                      <p className="tApp">Lazada</p>
                    </a>
                    <a
                      href="https://www.tiktok.com/@jibofficial"
                      className="social3"
                    >
                      <img src={img_icoSocial3} alt="Tiktok" />
                      <p className="tApp">Tiktok</p>
                    </a>
                  </div>
                </div>
              </div>
            </section>
            <MenuMember active="shop" />
          </div>
        </main>
      </div>
    </>
  );
};

export default ShopPage;
