import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setProfile } from "../../../features/ProfileSlice";
import { PatternFormat } from "react-number-format";
import {
  searchUser,
  updateUserProfile,
} from "../../../services/Api/Module/User";

import Helper, {
  convertDateFormat,
  convertMobileFormat,
  breakKeyDownEnter,
} from "../../../services/helper";
import moment from "moment";
import dayjs from "dayjs";

import PageHeader from "../../../components/header/PageHeader";
import ModalEditProfileSuccess from "../../../components/modal/editProfile/ModalEditProfileSuccess";
import ModalEditProfileChangeMobileNo from "../../../components/modal/editProfile/ModalEditProfileChangeMobileNo";

import useTranslations from "../../../i18n/useTranslations";

import img_imgMember from "../../../assets/img/ci/member/img-member.png";

const EditProfilePage = () => {
  const { t } = useTranslations();

  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateProfile = useSelector((state) => state.profile);

  const setDataProfile = (newData) => {
    const tmpObj = { dataUser: newData };
    dispatch(setProfile(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const setDataUpdateProfile = (updData = {}) => {
    const tmpObj = { updData: updData };
    dispatch(setProfile(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  /* Handle goto page */
  const navigate = useNavigate();

  const handleGotoPage = (e) => {
    e.preventDefault();
    // console.log("click GotoPage", e.currentTarget.dataset.page);

    const page = e.currentTarget.dataset.page;
    navigate("/" + page);
  };
  /* End Handle goto page */

  /* Form */
  const {
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    resetField,
    formState: { errors, isValid },
    setError,
    clearErrors,
    getFieldState,
  } = useForm({
    defaultValues: {
      firstname: "",
      lastname: "",
      gender: "",
      birthDate: "",
      mobileNo: "",
      email: "",
    },
  });

  const [formIsValid, setFormIsValid] = useState(false);
  const [formIsFocus, setFormIsFocus] = useState("");

  const _mobileNo = stateProfile.dataUser.mobileNo;

  const handleCheckOnChange = () => {
    const _field = getValues();
    let _invalidState = true,
      _invalidFields = [];

    for (const key in _field) {
      const val = _field[key];
      // console.log(key, typeof val, val);

      let _invalid = getFieldState(key).invalid;
      if (val === "" || val === false || val === null) {
        _invalid = true;
      }

      if (key === "mobileNo") {
        let _value = val;
        _value = _value.replaceAll("-", "");
        _value = _value.trim();

        if (_value.length === 10) {
          if (formIsFocus === "mobileNo") {
            if (_mobileNo === _value) {
              clearErrors("mobileNo");
            } else {
              handleChkMobileExist(_value);
            }
          }
          _invalid = false;
        } else {
          _invalid = true;
        }
      }

      if (key === "birthDate") {
        const _value = val;

        const selectedDate = dayjs(_value);
        const currentDate = dayjs();
        const age = currentDate.diff(selectedDate, "year");

        if (_value !== "") {
          if (age >= 13) {
            clearErrors("birthDate");

            _invalid = false;
          } else {
            setError(
              "birthDate",
              {
                type: "manual",
                message: t.form.birthDate.validate.value.minAge,
              },
              {
                shouldFocus: true,
              }
            );

            _invalid = true;
          }
        }
      }

      _invalidFields.push(_invalid);
    }

    _invalidState = _invalidFields.includes(true);

    if (_invalidState === true) {
      setFormIsValid(false);
    } else {
      setFormIsValid(true);
    }

    // console.log("onChange", _invalidFields, _invalidState);
  };

  const handleValidateAge = (value) => {
    // console.log("handleValidateAge >>", value)

    const selectedDate = dayjs(value);
    const currentDate = dayjs();
    const age = currentDate.diff(selectedDate, "year");

    return age >= 13 || t.form.birthDate.validate.value.minAge;
  };

  useEffect(() => {
    // console.log("formIsValid >>", formIsValid);
    // console.log("formIsFocus >>", formIsFocus);
  }, [formIsValid, formIsFocus]);
  /* End Form */

  /* Handle click submit form */
  const postUpdUserProfile = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await updateUserProfile({
        data: payload.data,
        id: payload.id,
      });
      // console.log("data.body >>", data);

      _return.result = data.resultData;

      if (data.resultCode === 20202) {
        _return.status = true;
      }
    } catch (e) {
      console.log("ERR postUpdUserProfile >> ", e);
    }

    return _return;
  };

  const onSubmit = (data) => {
    // console.log("onSubmit >>", data, stateProfile);

    const _newMobileNo = convertMobileFormat(data.mobileNo);

    const payload = {
      data: { ...data, mobileNo: _newMobileNo },
      id: stateProfile.id,
    };
    // console.log("payload >>", payload, _newMobileNo, _mobileNo);

    if (_newMobileNo === _mobileNo) {
      postUpdUserProfile(payload).then((_rsData) => {
        // console.log("_rsData >>", _rsData);

        if (_rsData.status === true) {
          setDataProfile(_rsData.result);

          handleOpenModelSuccess();
        } else {
          console.warn(_rsData);
        }
      });
    } else {
      setDataUpdateProfile(payload.data);

      handleOpenModelChangeMobileNo();
    }
  };
  /* End Handle click submit form */

  /* Check mobile nubmer exist from server  */
  const handleChkMobileExist = (mobileNo) => {
    // console.log(mobileNo);

    clearErrors("mobileNo");

    getSearchUser({
      mobileNo: mobileNo,
    }).then((_rsData) => {
      // console.log(_rsData);

      if (_rsData.status === true) {
        setFormIsValid(false);

        setError(
          "mobileNo",
          {
            type: "manual",
            message: t.form.mobileNo.validate.value.alreadyExist,
          },
          {
            shouldFocus: true,
          }
        );
      } else {
        clearErrors("mobileNo");
      }
    });
  };

  const getSearchUser = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await searchUser({ params: payload });
      // console.log("getSearchUser data >>", data);

      if (data.resultCode === 20200) {
        _return.status = true;
      }
    } catch (e) {
      console.log("ERR getSearchUser >> ", e);
    }

    return _return;
  };
  /* End Check mobile nubmer exist from server  */

  /* Modal update profile success */
  const [isModalSuccessShow, setIsModalSuccessShow] = useState(false);

  const handleOpenModelSuccess = () => {
    setIsModalSuccessShow(true);
  };

  const handleCloseModalSuccess = () => {
    setIsModalSuccessShow(false);

    setTimeout(() => navigate("/profile"), 200);
  };
  /* End Modal update profile success */

  /* Modal change mobile number */
  const [isModalChangeMobileNoShow, setIsModalChangeMobileNoShow] =
    useState(false);

  const handleOpenModelChangeMobileNo = () => {
    setIsModalChangeMobileNoShow(true);
  };

  const handleCloseModalChangeMobileNo = (changeMobileNo) => {
    setIsModalChangeMobileNoShow(false);

    if (changeMobileNo === true) {
      setTimeout(() => navigate("/profile/edit/otp/request"), 200);
    }
  };
  /* End Modal change mobile number */

  /* First event render */
  useEffect(() => {
    // console.log("useEffect !", stateProfile);

    if (Object.getOwnPropertyNames(stateProfile.dataUser).length === 0) {
      navigate("/");
    } else {
      let { dataUser } = stateProfile;

      setValue("firstname", dataUser.firstname);
      setValue("lastname", dataUser.lastname);
      setValue("gender", dataUser.gender);
      setValue("birthDate", convertDateFormat(dataUser.birthDate));
      setValue("mobileNo", convertMobileFormat(dataUser.mobileNo, "-"));
      setValue("email", dataUser.email);

      let _chkFormIsValid = true;
      const _chkField = [
        "firstname",
        "lastname",
        "gender",
        "birthDate",
        "mobileNo",
        "email",
      ];
      Object.entries(dataUser).forEach(([key, val]) => {
        if (_chkField.includes(key)) {
          if (val === "" || val === false || val === null) {
            _chkFormIsValid = false;
          }
        }
      });

      if (_chkFormIsValid === true) {
        setFormIsValid(true);
      }
    }
  }, []);
  /* End First event render */

  return (
    <>
      <Helmet>
        <title>JIB</title>
        <meta name="title" content="JIB" />
        <meta name="description" content="JIB" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="JIB" />
        <meta property="og:description" content="JIB" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="JIB" />
      </Helmet>

      <div id="pageEditProfile">
        <main>
          <PageHeader
            title={t.editProfilePage.headerTitle}
            btnBackImgClassName="block"
            btnHref="profile"
          />

          <div className="wrapContent">
            <ModalEditProfileSuccess
              open={isModalSuccessShow}
              onClose={handleCloseModalSuccess}
            />
            <ModalEditProfileChangeMobileNo
              open={isModalChangeMobileNoShow}
              onClose={(changeMobileNo) => {
                handleCloseModalChangeMobileNo(changeMobileNo);
              }}
            />

            <section id="pEditProfile">
              <form
                onSubmit={handleSubmit(onSubmit)}
                onKeyDown={(e) => breakKeyDownEnter(e)}
              >
                <div className="bForm">
                  <div className="bImg">
                    <div className="userImg">
                      <img src={stateProfile.dataUser.lineImageUrl} />
                    </div>
                    {/* <p className="tSmall">ID:{stateProfile.dataUser.id}</p> */}
                  </div>
                  <div
                    className={`control-group validate ${
                      errors.firstname ? "error" : ""
                    }`}
                  >
                    <div className="tTitle-validate">
                      {t.form.firstname.label}
                    </div>
                    <Controller
                      name="firstname"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: t.form.firstname.validate.required,
                      }}
                      render={({ field: { onChange, ...field } }) => (
                        <input
                          {...field}
                          type="text"
                          placeholder={t.form.firstname.placeholder}
                          onInput={Helper.FNFORM.handleCharOnly}
                          onChange={({ target: { value } }) => {
                            onChange(value);
                            handleCheckOnChange();
                          }}
                          onFocus={() => setFormIsFocus("firstname")}
                          maxLength="100"
                        />
                      )}
                    />
                    {errors.firstname && (
                      <label htmlFor="" className="tError">
                        {errors.firstname.message}
                      </label>
                    )}
                  </div>
                  <div
                    className={`control-group validate ${
                      errors.lastname ? "error" : ""
                    }`}
                  >
                    <div className="tTitle-validate">
                      {t.form.lastname.label}
                    </div>
                    <Controller
                      name="lastname"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: t.form.lastname.validate.required,
                      }}
                      render={({ field: { onChange, ...field } }) => (
                        <input
                          {...field}
                          type="text"
                          placeholder={t.form.lastname.placeholder}
                          onInput={Helper.FNFORM.handleCharOnly}
                          onChange={({ target: { value } }) => {
                            onChange(value);
                            handleCheckOnChange();
                          }}
                          onFocus={() => setFormIsFocus("lastname")}
                          maxLength="100"
                        />
                      )}
                    />
                    {errors.lastname && (
                      <label htmlFor="" className="tError">
                        {errors.lastname.message}
                      </label>
                    )}
                  </div>
                  <div
                    className={`control-group validate bSexual ${
                      errors.gender ? "error" : ""
                    }`}
                  >
                    <div className="tTitle-validate">{t.form.gender.label}</div>
                    <Controller
                      name="gender"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: t.form.gender.validate.required,
                      }}
                      render={({ field }) => (
                        <div className="bSelectRadio">
                          <label className="control control--radio">
                            {t.form.gender.value.m}
                            <input
                              {...field}
                              type="radio"
                              value="m"
                              checked={field.value === "m"}
                              onFocus={() => setFormIsFocus("gender")}
                            />
                            <div className="control__indicator"></div>
                          </label>
                          <label className="control control--radio">
                            {t.form.gender.value.f}
                            <input
                              {...field}
                              type="radio"
                              value="f"
                              checked={field.value === "f"}
                              onFocus={() => setFormIsFocus("gender")}
                            />
                            <div className="control__indicator"></div>
                          </label>
                          <label className="control control--radio">
                            {t.form.gender.value.x}
                            <input
                              {...field}
                              type="radio"
                              value="x"
                              checked={field.value === "x"}
                              onFocus={() => setFormIsFocus("gender")}
                            />
                            <div className="control__indicator"></div>
                          </label>
                        </div>
                      )}
                    />
                    {errors.gender && (
                      <label htmlFor="" className="tError">
                        {errors.gender.message}
                      </label>
                    )}
                  </div>
                  <div
                    className={`control-group validate ${
                      errors.birthDate ? "error" : ""
                    }`}
                  >
                    <div className="tTitle-validate">
                      {t.form.birthDate.label}
                    </div>
                    <Controller
                      name="birthDate"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: t.form.birthDate.validate.required,
                        validate: handleValidateAge,
                      }}
                      render={({ field: { onChange, ...field } }) => (
                        <input
                          {...field}
                          type="date"
                          onChange={({ target: { value } }) => {
                            onChange(value);
                            handleCheckOnChange();
                          }}
                          onFocus={() => setFormIsFocus("birthDate")}
                          disabled={true}
                        />
                      )}
                    />
                    {errors.birthDate && (
                      <label htmlFor="" className="tError">
                        {errors.birthDate.message}
                      </label>
                    )}
                  </div>
                  <div
                    className={`control-group validate ${
                      errors.mobileNo ? "error" : ""
                    }`}
                  >
                    <div className="tTitle-validate">
                      {t.form.mobileNo.label}
                    </div>
                    <Controller
                      name="mobileNo"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: t.form.mobileNo.validate.required,
                        pattern: {
                          value: /^\d{3}-\d{3}-\d{4}$/,
                          message: t.form.mobileNo.validate.pattern,
                        },
                      }}
                      render={({ field: { name, value, onChange } }) => (
                        <div className="inputIcon">
                          <PatternFormat
                            name={name}
                            value={value}
                            displayType="input"
                            format="###-###-####"
                            placeholder={t.form.mobileNo.placeholder}
                            onChange={({ target: { value } }) => {
                              onChange(value);
                              handleCheckOnChange();
                            }}
                            onFocus={() => setFormIsFocus("mobileNo")}
                          />
                        </div>
                      )}
                    />
                    {errors.mobileNo && (
                      <label htmlFor="" className="tError">
                        {errors.mobileNo.message}
                      </label>
                    )}
                  </div>
                  <div
                    className={`control-group validate ${
                      errors.email ? "error" : ""
                    }`}
                  >
                    <div className="tTitle-validate">{t.form.email.label}</div>
                    <Controller
                      name="email"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: t.form.email.validate.required,
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: t.form.email.validate.pattern,
                        },
                      }}
                      render={({ field: { onChange, ...field } }) => (
                        <input
                          {...field}
                          type="email"
                          placeholder={t.form.email.placeholder}
                          onChange={({ target: { value } }) => {
                            onChange(value);
                            handleCheckOnChange();
                          }}
                          disabled={true}
                          autoComplete="username"
                          onFocus={() => setFormIsFocus("email")}
                        />
                      )}
                    />
                    {errors.email && (
                      <label htmlFor="" className="tError">
                        {errors.email.message}
                      </label>
                    )}
                  </div>
                </div>
                <div className="bBtn">
                  <button
                    className="btn primary width-100"
                    type="submit"
                    disabled={formIsValid ? false : true}
                  >
                    {t.editProfilePage.btnSave}
                  </button>
                  <button
                    className="btn outline width-100"
                    onClick={handleGotoPage}
                    data-page="profile"
                  >
                    {t.btn.cancel}
                  </button>
                </div>
              </form>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default EditProfilePage;
