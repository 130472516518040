import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setRegister, resetRegister } from "../../features/RegisterSlice";
import { searchUser } from "../../services/Api/Module/User";

import Helper, {
  convertMobileFormat,
  breakKeyDownEnter,
} from "../../services/helper";
import { PatternFormat } from "react-number-format";

import ModalVerifyMobileNo from "../../components/modal/verify/ModalVerifyMobileNo";

import useTranslations from "../../i18n/useTranslations";

import img_icoLogo from "../../assets/img/ci/ico-logo.svg";

const VerifyPage = () => {
  const { t, lang, setLang } = useTranslations();

  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateRegister = useSelector((state) => state.register); // ไว้ดึง state

  const setDataRegister = (newData = {}) => {
    const tmpObj = { dataUser: newData };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const resetDataRegister = () => {
    dispatch(resetRegister()); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  /* Set state Register {currentPage} on goNext & goBack */
  const navigate = useNavigate();
  const [page, setPage] = useState("verify");

  const PageRoute = {
    verifyOtpRequest: "/verify/otp/request",
  };

  const setCurrentPage = (currentPage) => {
    const tmpObj = { currentPage: currentPage };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const setPrevPage = (prevPage) => {
    const tmpObj = { prevPage: prevPage };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const goBack = (newPage) => {
    setPrevPage(page);
    setCurrentPage(newPage);
    navigate(PageRoute[newPage]);
  };

  const goNext = (newPage) => {
    setPrevPage(page);
    setCurrentPage(newPage);
    navigate(PageRoute[newPage]);
  };
  /* End Set state Register {currentPage} on goNext & goBack */

  /* Form */
  const {
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    getFieldState,
  } = useForm({
    defaultValues: {
      mobileNo: "",
    },
  });

  const [formIsValid, setFormIsValid] = useState(false);
  const [formIsFocus, setFormIsFocus] = useState("");

  const handleCheckOnChange = () => {
    const _field = getValues();
    let _invalidState = true,
      _invalidFields = [];

    for (const key in _field) {
      const val = _field[key];
      // console.log(key, typeof val, val);

      let _invalid = getFieldState(key).invalid;
      if (val === "" || val === false || val === null) {
        _invalid = true;
      }

      if (key === "mobileNo") {
        let _value = val;
        _value = _value.replaceAll("-", "");
        _value = _value.trim();

        if (_value.length === 10) {
          if (formIsFocus === "mobileNo") {
            handleChkMobileExist(_value);
          }
        } else {
          _invalid = true;
        }
      }

      _invalidFields.push(_invalid);
    }

    _invalidState = _invalidFields.includes(true);

    if (_invalidState === true) {
      setFormIsValid(false);
    } else {
      setFormIsValid(true);
    }

    // console.log("onChange", _invalidState);
  };

  useEffect(() => {
    // console.log("formIsValid >>", formIsValid);
    // console.log("formIsFocus >>", formIsFocus);
  }, [formIsValid, formIsFocus]);
  /* End Form */

  /* Handle click submit form */
  const onSubmit = (data) => {
    // const newData = {
    //   mobileNo: data.mobileNo.replaceAll("-", ""),
    // };

    // setDataRegister(newData);

    goNext("verifyOtpRequest");
  };
  /* End Handle click submit form */

  /* Check mobile number exist from server */
  const handleChkMobileExist = (mobileNo) => {
    // console.log(mobileNo);

    // clearErrors("mobileNo");

    getSearchUser({
      mobileNo: mobileNo,
    }).then((_rsData) => {
      // console.log(_rsData);

      if (_rsData.status === true) {
        // setFormIsValid(false);

        // setError(
        //   "mobileNo",
        //   {
        //     type: "manual",
        //     message: `อีเมลนี้มีคนใช้ไปแล้ว`,
        //   },
        //   {
        //     shouldFocus: true,
        //   }
        // );

        setDataRegister(_rsData.result);

        setFormIsValid(true);
      } else {
        // clearErrors("mobileNo");

        // alert(
        //   "ขออภัย เราไม่พบข้อมูล\nกรุณาตรวจสอบข้อมูลให้ถูกต้อง\nและลองใหม่อีกครั้ง"
        // );

        handleToggleModel();

        setFormIsValid(false);
      }
    });
  };

  const getSearchUser = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await searchUser({ params: payload });
      // console.log("getSearchUser data >>", data);

      if (data.resultCode === 20200) {
        _return.status = true;
        _return.result = data.resultData;
      }
    } catch (e) {
      console.log("ERR getSearchUser >> ", e);
    }

    return _return;
  };
  /* End Check mobile number exist from server  */

  /* Modal verify mobile number */
  const [isModalShow, setIsModalShow] = useState(false);

  const handleToggleModel = () => {
    setIsModalShow(isModalShow ? false : true);
  };
  /* End Modal verify mobile number */

  /* First event render */
  // useEffect(() => {
  //   // console.log("useEffect !", stateRegister);
  // }, []);
  /* End First event render */

  useEffect(() => {
    // console.log("useEffect !", stateRegister);
  }, [stateRegister.dataUser]);

  /* Handle select language */
  const handleSelectLanguage = (e) => {
    setLang(e.target.value);
  };

  // useEffect(() => {
  //   console.log(lang);
  // }, [lang]);
  /* End Handle select language */

  return (
    <>
      <Helmet>
        <title>JIB</title>
        <meta name="title" content="JIB" />
        <meta name="description" content="JIB" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="JIB" />
        <meta property="og:description" content="JIB" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="JIB" />
      </Helmet>

      <div id="pageEmailLogin">
        <main>
          <div className="wrapContent">
            <ModalVerifyMobileNo
              open={isModalShow}
              onClose={handleToggleModel}
            />
            <section id="pEmailLogin">
              <div className="bTitle">
                <div className="dummy"></div>
                <div className="bLogo">
                  <img src={img_icoLogo} alt="JIB" />
                  <div className="bText">
                    <h1 className="tHead">{t.verifyPage.headerTitle}</h1>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: t.verifyPage.bodyTitle,
                      }}
                    ></p>
                  </div>
                </div>
                <select
                  name="lang"
                  id="languge"
                  data-lang={lang}
                  value={lang}
                  onChange={handleSelectLanguage}
                >
                  {lang === "th" ? (
                    <>
                      <option value="th">ไทย</option>
                      <option value="en">Eng</option>
                    </>
                  ) : (
                    <>
                      <option value="en">Eng</option>
                      <option value="th">ไทย</option>
                    </>
                  )}
                </select>
              </div>
              <form
                onSubmit={handleSubmit(onSubmit)}
                onKeyDown={(e) => breakKeyDownEnter(e)}
              >
                <div className="bForm">
                  <div
                    className={`control-group validate ${
                      errors.mobileNo ? "error" : ""
                    }`}
                  >
                    <div className="tTitle">{t.verifyPage.mobileNoLabel}</div>
                    <Controller
                      name="mobileNo"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: t.form.mobileNo.validate.required,
                        pattern: {
                          value: /^\d{3}-\d{3}-\d{4}$/,
                          message: t.form.mobileNo.validate.pattern,
                        },
                      }}
                      render={({ field: { name, value, onChange } }) => (
                        <div className="inputIcon">
                          <PatternFormat
                            name={name}
                            value={value}
                            displayType="input"
                            format="###-###-####"
                            placeholder={t.form.mobileNo.placeholder}
                            onChange={({ target: { value } }) => {
                              onChange(value);
                              handleCheckOnChange();
                            }}
                            onFocus={() => setFormIsFocus("mobileNo")}
                          />
                        </div>
                      )}
                    />
                    {errors.mobileNo && (
                      <label htmlFor="" className="tError">
                        {errors.mobileNo.message}
                      </label>
                    )}
                  </div>

                  <button
                    className="btn second btnDisRegis"
                    type="submit"
                    disabled={formIsValid ? false : true}
                  >
                    {t.verifyPage.btnNext}
                  </button>
                </div>
              </form>
              <div className="bNavRegister">
                <p>
                  {t.verifyPage.btnRegister["0"]}{" "}
                  <a
                    href="#"
                    onClick={() => {
                      navigate("/register");
                    }}
                  >
                    {t.verifyPage.btnRegister["1"]}
                  </a>
                </p>
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default VerifyPage;
