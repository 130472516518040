import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setProfile } from "../../../features/ProfileSlice";
import { userProfile } from "../../../services/Api/Module/User";
import { banner } from "../../../services/Api/Module/Banner";

import Helper, {
  convertDateFormat,
  convertMobileFormat,
  breakKeyDownEnter,
} from "../../../services/helper";
import moment from "moment";

import PageHeader from "../../../components/header/PageHeader";
import MenuMember from "../../../components/menu/MenuMember";
import BannerProfile from "../../../components/banner/bannerProfile";

import useTranslations from "../../../i18n/useTranslations";

import img_imgBanner1 from "../../../assets/img/ci/banner/img-banner-1.png";
import img_icoLogoHead from "../../../assets/img/ci/ico-logo-head.svg";
import img_imgMember from "../../../assets/img/ci/member/img-member.png";
import img_icoClame from "../../../assets/img/ci/ico-clame.svg";
import img_icoDeliver from "../../../assets/img/ci/ico-deliver.svg";
import img_icoGift from "../../../assets/img/ci/ico-gift.svg";
import img_icoEdit from "../../../assets/img/ci/ico-edit.svg";

const ProfilePage = () => {
  const { t, lang } = useTranslations();

  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateProfile = useSelector((state) => state.profile);

  const setDataProfile = (newData) => {
    const tmpObj = { dataUser: newData };
    dispatch(setProfile(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const setDataAddress = (
    newData = {
      delivery: {},
      taxBilling: {},
    }
  ) => {
    const _address = {
      ...stateProfile.dataAddress,
      ...newData,
    };

    const tmpObj = {
      dataAddress: _address,
    };
    dispatch(setProfile(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  /* Handle goto page */
  const navigate = useNavigate();

  const handleGotoPage = (e) => {
    e.preventDefault();
    // console.log("click GotoPage", e.currentTarget.dataset.page);

    const page = e.currentTarget.dataset.page;
    navigate("/" + page);
  };
  /* End Handle goto page */

  /* First event render */
  const [isLoadProfile, setIsLoadProfile] = useState(false);
  const [bannerList, setBannerList] = useState([]);

  const getUserProfile = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await userProfile({ params: payload });
      // console.log("getUserProfile data >>", data);

      if (data.resultCode === 20200) {
        _return.status = true;
        _return.result = data.resultData;
      }
    } catch (e) {
      console.log("ERR getUserProfile >> ", e);
    }

    return _return;
  };

  const getBanner = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await banner({ params: payload });
      // console.log("getBanner data >>", data);

      if (data.resultCode === 20200) {
        _return.status = true;
        _return.result = data.resultData;
      }
    } catch (e) {
      console.log("ERR getBanner >> ", e);
    }

    return _return;
  };

  useEffect(() => {
    // console.log("useEffect !", stateProfile);

    if (stateProfile.id === 0) {
      navigate("/");
    } else {
      getBanner({
        platformId: 1,
        pageId: 1,
      }).then((_rsData) => {
        // console.log("_rsData >>", _rsData);

        if (_rsData.status === true) {
          if (_rsData.result !== null) {
            if (
              typeof _rsData.result.bannerPages[0] !== "undefined" &&
              typeof _rsData.result.bannerPages[0].bannerList !== "undefined"
            ) {
              const _bannerlist = _rsData.result.bannerPages[0].bannerList;

              for (let i = 0; i < _bannerlist.length; i++) {
                setBannerList((prevItem) => [...prevItem, _bannerlist[i]]);
              }
            }
          }
        }
      });

      if (isLoadProfile === false) {
        getUserProfile({
          userId: stateProfile.id,
        }).then((_rsData) => {
          // console.log("_rsData >>", _rsData);

          if (_rsData.status === true) {
            // console.log(_rsData.result.address, _rsData.result.address.length);

            if (_rsData.result.address.length > 0) {
              // console.log("true", _rsData.result.address.length);

              const _dataAddress = _rsData.result.address;
              let _tmpAddress = {
                delivery: {},
                taxBilling: {},
              };

              _dataAddress.map((item, index) => {
                // console.log(item);

                if (item.addressType === 1) {
                  _tmpAddress = {
                    ..._tmpAddress,
                    delivery: _dataAddress[index],
                  };
                } else if (item.addressType === 2) {
                  _tmpAddress = {
                    ..._tmpAddress,
                    taxBilling: _dataAddress[index],
                  };
                }

                setDataAddress(_tmpAddress);
              });
            } else {
              // console.log("else", _rsData.result.address.length);

              setDataAddress("");
            }

            const _dataUser = _rsData.result;
            if (typeof _dataUser.address !== "undefined") {
              delete _dataUser.address;
            }

            setDataProfile({
              ...stateProfile.dataUser,
              ..._dataUser,
            });
          }

          setIsLoadProfile(true);
        });
      }
    }
  }, []);

  useEffect(() => {
    // if (isLoadProfile) {
    //   console.log("isLoadProfile >>", isLoadProfile, stateProfile);
    // }
  }, [isLoadProfile, stateProfile]);
  /* End First event render */

  return (
    <>
      <Helmet>
        <title>JIB</title>
        <meta name="title" content="JIB" />
        <meta name="description" content="JIB" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="JIB" />
        <meta property="og:description" content="JIB" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="JIB" />
      </Helmet>

      <div id="pageProfile">
        <main>
          <PageHeader title="" btnBackImgClassName="" btnHref="" />

          <div className="wrapContent">
            <section id="pProfile">
              {bannerList.length > 0 &&
                (bannerList.length > 1 ? (
                  <BannerProfile banners={bannerList} />
                ) : (
                  <div className="slider">
                    {bannerList.map((bannerItem, bannerIndex) => (
                      <div key={bannerIndex + 1} className="bBannerImg">
                        {bannerItem.embedLink !== null ||
                        bannerItem.embedLink !== "" ? (
                          <a href={bannerItem.embedLink}>
                            <img src={bannerItem.imageDesktop} />
                          </a>
                        ) : (
                          <img src={bannerItem.imageDesktop} />
                        )}
                      </div>
                    ))}
                  </div>
                ))}
              <div className="bMemberCard">
                <div className="bBanner">
                  <img src={img_icoLogoHead} />
                  <p>MEMBER</p>
                </div>
                <div className="bImg">
                  <div className="userImg">
                    <img src={stateProfile.dataUser.lineImageUrl} />
                  </div>
                  {/* <p className="tSmall">ID:{stateProfile.id}</p> */}
                </div>
                <div className="tUserDetail">
                  <p className="tName">{`${stateProfile.dataUser.firstname}  ${stateProfile.dataUser.lastname}`}</p>
                  <div className="bPoint">
                    <p className="tSmall">
                      {t.profilePage.memberCard.point["0"]}
                    </p>
                    <p className="tBold">
                      {Helper.FN.toNumberWithCommas(
                        stateProfile.dataUser.currentPoint
                      )}
                      <span>{t.profilePage.memberCard.point["1"]}</span>
                    </p>
                    {stateProfile.dataUser.currentPoint !== 0 && (
                      <p className="tSmall">
                        {t.profilePage.memberCard.pointExpired}{" "}
                        {new Date(
                          stateProfile.dataUser.pointExpiredDate
                        ).toLocaleDateString(
                          lang === "th" ? "th-TH" : "en-EN",
                          {
                            year: "numeric",
                            month: "short",
                            day: "2-digit",
                          }
                        )}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="bLink">
                <a className="bLeft" href="">
                  <img src={img_icoClame} />
                  <p>{t.profilePage.menuTop["0"]}</p>
                </a>

                <a className="bCenter" href="">
                  <img src={img_icoDeliver} />
                  <p>{t.profilePage.menuTop["1"]}</p>
                </a>

                <a className="bRight" href="">
                  <img src={img_icoGift} />
                  <p>{t.profilePage.menuTop["2"]}</p>
                </a>
              </div>
              <div className="bForm">
                <div className="bHead">
                  <p>{t.profilePage.detail.headerTitle}</p>
                  <a
                    href="#"
                    className="bEdit"
                    onClick={handleGotoPage}
                    data-page="profile/edit"
                  >
                    <img src={img_icoEdit} />
                    <p>{t.profilePage.edit}</p>
                  </a>
                </div>
                <div className="bDetail">
                  <p>{t.profilePage.detail.fullname}</p>
                  <p className="tData">{`${stateProfile.dataUser.firstname}  ${stateProfile.dataUser.lastname}`}</p>
                  <p>{t.profilePage.detail.birthDate}</p>
                  <p className="tData">
                    {stateProfile.dataUser.birthDate &&
                      // moment(stateProfile.dataUser.birthDate).format(
                      //   "DD MM YYYY"
                      // )}
                      new Date(
                        stateProfile.dataUser.birthDate
                      ).toLocaleDateString(lang === "th" ? "th-TH" : "en-EN", {
                        year: "numeric",
                        month: "long",
                        day: "2-digit",
                      })}
                  </p>
                  <p>{t.form.gender.label}</p>
                  <p className="tData">
                    {stateProfile.dataUser.gender === "m"
                      ? t.form.gender.value.m
                      : stateProfile.dataUser.gender === "f"
                      ? t.form.gender.value.f
                      : stateProfile.dataUser.gender === "x"
                      ? t.form.gender.value.x
                      : ""}
                  </p>
                  <p>{t.profilePage.detail.mobileNo}</p>
                  <p className="tData">
                    {Helper.FORMAT.TEL(stateProfile.dataUser.mobileNo)}
                  </p>
                  <p>{t.profilePage.detail.email}</p>
                  <p className="tData">{stateProfile.dataUser.email}</p>
                </div>
              </div>
              <div className="bAddressForm">
                <div className="bHead">
                  <p>{t.profilePage.address.delivery.title}</p>
                  <a
                    href="#"
                    className="bEdit"
                    onClick={handleGotoPage}
                    data-page="profile/address/delivery"
                  >
                    <img src={img_icoEdit} />
                    <p>{t.profilePage.edit}</p>
                  </a>
                </div>
                <div className="bDetail">
                  {Object.getOwnPropertyNames(stateProfile.dataAddress.delivery)
                    .length ? (
                    <>
                      <div className="tName">
                        <p>{stateProfile.dataAddress.delivery.name}</p>
                        <div className="border"></div>
                        <p className="tData">
                          {convertMobileFormat(
                            stateProfile.dataAddress.delivery.mobileNo,
                            "-"
                          )}
                        </p>
                      </div>
                      <p>{`${stateProfile.dataAddress.delivery.addressDetail} ${
                        stateProfile.dataAddress.delivery.tombon &&
                        stateProfile.dataAddress.delivery.tombon.nameTh
                      } ${
                        stateProfile.dataAddress.delivery.amphure &&
                        stateProfile.dataAddress.delivery.amphure.nameTh
                      } ${
                        stateProfile.dataAddress.delivery.province &&
                        stateProfile.dataAddress.delivery.province.nameTh
                      } ${stateProfile.dataAddress.delivery.postcode}`}</p>
                    </>
                  ) : (
                    <p>{t.profilePage.address.delivery.isEmpty}</p>
                  )}
                </div>
              </div>
              <div className="bAddressForm">
                <div className="bHead">
                  <p>{t.profilePage.address.tax.title}</p>
                  <a
                    href="#"
                    className="bEdit"
                    onClick={handleGotoPage}
                    data-page="profile/address/tax-billing"
                  >
                    <img src={img_icoEdit} />
                    <p>{t.profilePage.edit}</p>
                  </a>
                </div>
                <div className="bDetail">
                  {Object.getOwnPropertyNames(
                    stateProfile.dataAddress.taxBilling
                  ).length ? (
                    <>
                      <div className="tName">
                        <p>{stateProfile.dataAddress.taxBilling.name}</p>
                        <div className="border"></div>
                        <p className="tData">
                          {convertMobileFormat(
                            stateProfile.dataAddress.taxBilling.mobileNo,
                            "-"
                          )}
                        </p>
                      </div>
                      <p>{`${
                        stateProfile.dataAddress.taxBilling.addressDetail
                      } ${
                        stateProfile.dataAddress.taxBilling.tombon &&
                        stateProfile.dataAddress.taxBilling.tombon.nameTh
                      } ${
                        stateProfile.dataAddress.taxBilling.amphure &&
                        stateProfile.dataAddress.taxBilling.amphure.nameTh
                      } ${
                        stateProfile.dataAddress.taxBilling.province &&
                        stateProfile.dataAddress.taxBilling.province.nameTh
                      } ${stateProfile.dataAddress.taxBilling.postcode}`}</p>
                    </>
                  ) : (
                    <p>{t.profilePage.address.tax.isEmpty}</p>
                  )}
                </div>
              </div>
            </section>
            <MenuMember active="profile" />
          </div>
        </main>
      </div>
    </>
  );
};

export default ProfilePage;
