import React from "react";
import { useNavigate } from "react-router-dom";

import useTranslations from "../../i18n/useTranslations";

const MenuMember = (props) => {
  const { t } = useTranslations();

  /* Handle goto page */
  const navigate = useNavigate();

  const handleGotoPage = (e) => {
    e.preventDefault();
    // console.log("click GotoPage", e.currentTarget.dataset.page);

    const page = e.currentTarget.dataset.page;
    navigate("/" + page);
  };
  /* End Handle goto page */

  return (
    <>
      <div id="pMenu">
        <a
          href="#"
          className={`bMenu ${props.active === "profile" ? "active" : ""}`}
          onClick={handleGotoPage}
          data-page="profile"
        >
          <div
            className={`bgImg ${props.active === "profile" ? "active" : ""}`}
          >
            <i className="menu1"></i>
          </div>
          <p>{t.menuMember["0"]}</p>
        </a>
        <a
          href="#"
          className={`bMenu ${props.active === "warranty" ? "active" : ""}`}
          onClick={handleGotoPage}
          data-page="warranty"
        >
          <div
            className={`bgImg ${props.active === "warranty" ? "active" : ""}`}
          >
            <i className="menu2"></i>
          </div>
          <p>{t.menuMember["1"]}</p>
        </a>
        <a
          href="#"
          className={`bMenu ${props.active === "order" ? "active" : ""}`}
          onClick={handleGotoPage}
          data-page="order"
        >
          <div className={`bgImg ${props.active === "order" ? "active" : ""}`}>
            <i className="menu3"></i>
          </div>
          <p>{t.menuMember["2"]}</p>
        </a>
        <a
          href="#"
          className={`bMenu ${props.active === "shop" ? "active" : ""}`}
          onClick={handleGotoPage}
          data-page="shop"
        >
          <div className={`bgImg ${props.active === "shop" ? "active" : ""}`}>
            <i className="menu4"></i>
          </div>
          <p>{t.menuMember["3"]}</p>
        </a>
      </div>
    </>
  );
};

export default MenuMember;
