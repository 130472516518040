import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setRegister, resetRegister } from "../../features/RegisterSlice";
import { setProfile } from "../../features/ProfileSlice";
import { survey } from "../../services/Api/Module/Survey";
import { updateUserVerify } from "../../services/Api/Module/User";

import PageStep from "../../components/step/PageStep";

import Helper, {
  convertMobileFormat,
  breakKeyDownEnter,
} from "../../services/helper";

import ModalPrivacyAndNews from "../../components/modal/ModalPrivacyAndNews";

import useTranslations from "../../i18n/useTranslations";

import img_icoLogoHead from "../../assets/img/ci/ico-logo-head.svg";

const VerifySurveyPage = () => {
  const { t, lang, setLang } = useTranslations();

  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateRegister = useSelector((state) => state.register); // ไว้ดึง state
  const stateProfile = useSelector((state) => state.profile);

  const resetDataRegis = () => {
    dispatch(resetRegister());
  };

  const setDataProfile = (newData) => {
    const tmpObj = { id: newData.id, dataUser: newData };
    dispatch(setProfile(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  /* Set state Register {currentPage} on goNext & goBack */
  const navigate = useNavigate();
  const [page, setPage] = useState("verifySurvey");

  const PageRoute = {
    verifyForm: "/verify/form",
    verifySuccess: "/verify/success",
  };

  const setCurrentPage = (currentPage) => {
    const tmpObj = { currentPage: currentPage };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const setPrevPage = (prevPage) => {
    const tmpObj = { prevPage: prevPage };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const goBack = (newPage) => {
    setPrevPage(page);
    setCurrentPage(newPage);
    navigate(PageRoute[newPage]);
  };

  const goNext = (newPage) => {
    setPrevPage(page);
    setCurrentPage(newPage);
    navigate(PageRoute[newPage]);
  };
  /* End Set state Register {currentPage} on goNext & goBack */

  /* Form */
  const {
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    getFieldState,
  } = useForm({
    defaultValues: {
      survey: [],
      isPdpa: false,
      isNewsAndPro: false,
    },
  });

  const [formIsValid, setFormIsValid] = useState(false);

  const handleCheckOnChange = () => {
    const _field = getValues();
    let _invalidState = true,
      _invalidFields = [];

    for (const key in _field) {
      const val = _field[key];
      // console.log(key, typeof val, val);

      let _invalid = getFieldState(key).invalid;
      if (val === "" || val === false || val === null) {
        _invalid = true;
      }

      if (key === "survey") {
        let _value = val;

        if (_value.length === 0) {
          _invalid = true;
        }
      }

      if (key === "isNewsAndPro") {
        _invalid = false;
      }

      _invalidFields.push(_invalid);
    }

    _invalidState = _invalidFields.includes(true);

    if (_invalidState === true) {
      setFormIsValid(false);
    } else {
      setFormIsValid(true);
    }

    // console.log("onChange", _invalidState);
  };

  useEffect(() => {
    // console.log(formIsValid);
  }, [formIsValid]);
  /* End Form */

  /* onClick privacy policy save form data temp  */
  const setDataSurvey = (newData = {}) => {
    const tmpObj = { dataSurvey: newData };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const setTempDataSurvey = () => {
    const updateData = {
      survey: watch("survey"),
      isPdpa: watch("isPdpa"),
      isNewsAndPro: watch("isNewsAndPro"),
    };
    // console.log("setTempDataRegis >>", updateData);

    setDataSurvey(updateData);
  };
  /* End onClick privacy policy save form data temp  */

  /* Handle click submit form */
  const postUpdUserVerify = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await updateUserVerify({ data: payload });
      // console.log("data.body >>", data);

      _return.result = data.resultData;

      if (data.resultCode === 20202) {
        _return.status = true;
      }
    } catch (e) {
      console.log("ERR postUpdUserVerify >> ", e);
    }

    return _return;
  };

  const onSubmit = (data) => {
    // console.log("onSubmit >>", data, stateRegister);

    const payload = {
      ...stateRegister.dataUser,
      ...data,
      lineUid: stateProfile.lineUid,
      lineImageUrl: stateProfile.lineImgUrl,
    };
    delete payload.passwordConfirm;
    // console.log("payload >>", payload);

    postUpdUserVerify(payload).then((_rsData) => {
      // console.log("_rsData >>", _rsData);

      if (_rsData.status === true) {
        setDataProfile(_rsData.result);

        resetDataRegis();

        goNext("verifySuccess");
      } else {
        console.warn(_rsData);
      }
    });
  };
  /* End Handle click submit form */

  /* Modal privacy policy and news */
  const [isModalShow, setIsModalShow] = useState(false);
  const [modalType, setIsModalType] = useState(null);

  const handleToggleModel = () => {
    setIsModalShow(isModalShow ? false : true);
  };
  /* End Modal privacy policy and news */

  /* First event render */
  const [surveyList, setSurveyList] = useState([]);

  const getSurvey = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await survey({ params: payload });
      // console.log("getSurvey data >>", data);

      if (data.resultCode === 20200) {
        _return.status = true;
        _return.result = data.resultData;
      }
    } catch (e) {
      console.log("ERR getSurvey >> ", e);
    }

    return _return;
  };

  useEffect(() => {
    // console.log("useEffect !", stateRegister);

    getSurvey({}).then((_rsData) => {
      // console.log(_rsData);

      if (_rsData.status === true) {
        const _length = _rsData.result.length;
        const _survey = _rsData.result;
        // console.log(_survey, _length);

        for (let i = 0; i < _length; i++) {
          setSurveyList((prevItem) => [...prevItem, _survey[i]]);
        }
      }
    });

    if (Object.getOwnPropertyNames(stateRegister.dataUser).length === 0) {
      navigate("/");
    } else {
      const dataSurvey = stateRegister.dataSurvey;

      if (Object.keys(dataSurvey).length !== 0) {
        setValue("survey", dataSurvey.survey);
        setValue("isPdpa", dataSurvey.isPdpa);
        setValue("isNewsAndPro", dataSurvey.isNewsAndPro);

        let _chkFormIsValid = true;
        const _chkField = ["survey", "isPdpa"];
        Object.entries(dataSurvey).forEach(([key, val]) => {
          if (_chkField.includes(key)) {
            if (val === "" || val === false || val === null) {
              _chkFormIsValid = false;
            }
          }
        });

        if (_chkFormIsValid === true) {
          setFormIsValid(true);
        }
      }
    }
  }, []);
  /* End First event render */

  useEffect(() => {
    // console.log("surveyList >>", surveyList);
  }, [surveyList]);

  useEffect(() => {
    // console.log("stateRegister survey >>", stateRegister.dataSurvey);
  }, [stateRegister.dataSurvey]);

  /* Handle select language */
  const handleSelectLanguage = (e) => {
    setLang(e.target.value);
  };

  // useEffect(() => {
  //   console.log(lang);
  // }, [lang]);
  /* End Handle select language */

  return (
    <>
      <Helmet>
        <title>JIB</title>
        <meta name="title" content="JIB" />
        <meta name="description" content="JIB" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="JIB" />
        <meta property="og:description" content="JIB" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="JIB" />
      </Helmet>

      <div id="pageRegisterSurvey">
        <header>
          <div className="bHeader">
            <div className="dummy"></div>
            <div className="tHead">
              <img src={img_icoLogoHead} />
              <h1>{t.verifySurveyPage.headerTitle}</h1>
            </div>
            <select
              name="lang"
              id="languge"
              data-lang={lang}
              value={lang}
              onChange={handleSelectLanguage}
            >
              {lang === "th" ? (
                <>
                  <option value="th">ไทย</option>
                  <option value="en">Eng</option>
                </>
              ) : (
                <>
                  <option value="en">Eng</option>
                  <option value="th">ไทย</option>
                </>
              )}
            </select>
          </div>
        </header>
        <main>
          <div className="wrapContent">
            <ModalPrivacyAndNews
              open={isModalShow}
              onClose={handleToggleModel}
              modalType={modalType}
            />

            <section id="pRegisterSurvey">
              <form
                onSubmit={handleSubmit(onSubmit)}
                onKeyDown={(e) => breakKeyDownEnter(e)}
              >
                {surveyList.length > 0 &&
                  surveyList.map((questionItem, questionIdx) => (
                    <React.Fragment key={questionIdx + 1}>
                      <div className="bHead">
                        <h1>
                          {lang === "th"
                            ? questionItem.questionTh
                            : questionItem.questionEn}
                        </h1>
                        <p className="tTitle">{t.verifySurveyPage.bodyTitle}</p>
                        <p className="tSubtitle">
                          {t.verifySurveyPage.bodySubtitle}
                        </p>
                      </div>
                      <div className="bSurvey">
                        <div
                          className="groupSurvey"
                          style={{
                            flexFlow: "wrap",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {questionItem.answer.length > 0 &&
                            questionItem.answer.map((answerItem, answerIdx) => (
                              <Controller
                                key={`${questionItem.id}_${answerItem.id}`}
                                name="survey"
                                control={control}
                                rules={{
                                  validate: (value) =>
                                    value.length > 0 ||
                                    t.verifySurveyPage.surveyRequired,
                                }}
                                render={({ field }) => (
                                  <label
                                    className="surveySelect"
                                    htmlFor={`survey${questionItem.id}_${answerItem.id}`}
                                  >
                                    <input
                                      {...field}
                                      type="checkbox"
                                      checked={field.value.includes(
                                        `${questionItem.id}_${answerItem.id}`
                                      )}
                                      onChange={(e) => {
                                        const { checked, value } = e.target;

                                        if (checked) {
                                          field.onChange([
                                            ...field.value,
                                            value,
                                          ]);
                                        } else {
                                          field.onChange(
                                            field.value.filter(
                                              (v) => v !== value
                                            )
                                          );
                                        }

                                        handleCheckOnChange();
                                      }}
                                      value={`${questionItem.id}_${answerItem.id}`}
                                      id={`survey${questionItem.id}_${answerItem.id}`}
                                    />
                                    <div className="control__indicator">
                                      <p>
                                        {lang === "th"
                                          ? answerItem.answerTh
                                          : answerItem.answerEn}
                                      </p>
                                    </div>
                                  </label>
                                )}
                              />
                            ))}
                        </div>
                        {surveyList.length === questionIdx + 1 && (
                          <>
                            {errors.survey && (
                              <label htmlFor="" className="tError">
                                {errors.survey.message}
                              </label>
                            )}
                            <PageStep
                              title={t.verifySurveyPage.pageStepTitle}
                              stepClassName="mt-24"
                              stepTotal="2"
                              stepActive="2"
                            />
                          </>
                        )}
                      </div>
                    </React.Fragment>
                  ))}
                <div className="bPdpa">
                  <div
                    className={`control-group validate ${
                      errors.isPdpa ? "error" : ""
                    }`}
                  >
                    <div className="bInfo">
                      <label className="control control--checkbox validate">
                        <p>{t.verifySurveyPage.btnPrivacyPolicy["0"]}</p>
                        <Controller
                          name="isPdpa"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: `กรุณายอมรับ`,
                          }}
                          render={({ field }) => (
                            <input
                              {...field}
                              type="checkbox"
                              checked={watch("isPdpa")}
                              onChange={(e) => {
                                field.onChange(e.target.checked);
                                handleCheckOnChange();
                              }}
                            />
                          )}
                        />
                        <div className="control__indicator"></div>
                      </label>
                      <a
                        href="#"
                        onClick={() => {
                          setIsModalType("privacy");
                          handleToggleModel();
                        }}
                      >
                        {t.verifySurveyPage.btnPrivacyPolicy["1"]}
                      </a>
                    </div>
                    {errors.isPdpa && (
                      <label htmlFor="" className="tError">
                        {errors.isPdpa.message}
                      </label>
                    )}
                  </div>
                  <div
                    className={`control-group validate ${
                      errors.isNewsAndPro ? "error" : ""
                    }`}
                  >
                    <div className="bInfo">
                      <label className="control control--checkbox">
                        <p>{t.verifySurveyPage.btnNewsAndPromotions["0"]}</p>
                        <Controller
                          name="isNewsAndPro"
                          control={control}
                          defaultValue=""
                          // rules={{
                          //   required: `กรุณายอมรับ`,
                          // }}
                          render={({ field }) => (
                            <input
                              {...field}
                              type="checkbox"
                              checked={watch("isNewsAndPro")}
                              onChange={(e) => {
                                field.onChange(e.target.checked);
                                handleCheckOnChange();
                              }}
                            />
                          )}
                        />
                        <div className="control__indicator"></div>
                      </label>
                      <a
                        href="#"
                        onClick={() => {
                          setIsModalType("news");
                          handleToggleModel();
                        }}
                      >
                        {t.verifySurveyPage.btnNewsAndPromotions["1"]}
                      </a>
                    </div>
                    {errors.isNewsAndPro && (
                      <label htmlFor="" className="tError">
                        {errors.isNewsAndPro.message}
                      </label>
                    )}
                  </div>
                </div>
                <div className="bBtn">
                  <button
                    className="btn"
                    type="submit"
                    disabled={formIsValid ? false : true}
                  >
                    {t.verifySurveyPage.btnNext}
                  </button>
                  {/* <button
                    className="btn outline"
                    onClick={(e) => {
                      e.preventDefault();
                      goBack("verifyForm");
                    }}
                  >
                    {t.btn.back}
                  </button> */}
                </div>
              </form>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default VerifySurveyPage;
