import React from "react";

const PageStep = (props) => {

  const stepTotal = parseInt(props.stepTotal);
  const stepActive = parseInt(props.stepActive);

  let stepElement = [];

  for (let i = 0; i < stepTotal; i++) {
    stepElement.push(<div className={`step ${i < stepActive ? "active" : ""}`} key={i + 1}></div>);
  }

  return (
    <>
      <div id="pageStep">
        <div className={`bStep ${props.stepClassName}`}>
          {stepElement}
        </div>
        <p>{props.title}</p>
      </div>
    </>
  );
};

export default PageStep;
