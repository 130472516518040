import { get, post, put } from "../Config";

export function province({ params = {} }) {
  return get({ url: `/api/jib/line/v1/user/address/province`, params });
}

export function userAddress({ params = {} }) {
  return get({ url: `/api/jib/line/v1/user/address`, params });
}

export function createAddress({ data = {} }) {
  return post({ url: `/api/jib/line/v1/user/address/create`, data, timeOut: 20000 });
}

export function updateAddress({ data = {} }) {
  return put({
    url: `/api/jib/line/v1/user/address/update/${data.id}`,
    data,
    timeOut: 20000,
  });
}
