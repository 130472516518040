import { createSlice } from "@reduxjs/toolkit";

const defaultData = {
  id: 0,
  data: {},
  image: null
};

export const PromotionSlice = createSlice({
  name: "Promotion",
  initialState: defaultData,
  reducers: {
    setPromotion: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        // console.log(`${key}: ${value}`);
        state[key] = value;
      }
    },
    resetPromotion: () => defaultData,
  },
});

// Action creators are generated for each case reducer function
export const { setPromotion, resetPromotion } = PromotionSlice.actions;

export default PromotionSlice.reducer;
